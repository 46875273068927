.deposit {
  & .disclaimer {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 11px;
    background-color: #1B1F2A;
    border-radius: 12px;

    &__alert {
      padding: 5px 16px;
      font-weight: 800;
      font-size: 20px;
      line-height: 150%;
      color: #F7442D;
      background-color: rgba(247, 68, 45, 0.1);
      border-radius: 16px;
    }

    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.6);
    }

    @media (max-width: 767.9px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &__alert {
        padding: 3px 10px;
        font-size: 12px;
        border-radius: 10px;
      }
    }
  }
}