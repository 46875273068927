.mines {
  &__field {
    margin-top: 24.5px;
    padding-top: 83px;
    padding-bottom: 124px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &.forest {
      margin-top: -25px;
      background: url('../../../assets/mines/map-forest.svg') center center/contain no-repeat;

      @media (min-width: 1680px),
      (max-width: 1280px) and (min-width: 1025px),
      (max-width: 700px) {
        background-size: cover;
      }

      background-position: center;

    }

    &.desert {
      margin-top: -20px;
      background: url('../../../assets/mines/map-desert.svg') center center/contain no-repeat;

      @media (min-width: 1680px),
      (max-width: 1280px) and (min-width: 1025px),
      (max-width: 664px) {
        background-size: cover;
      }

    }

    &.default {
      padding: 0;
      margin-top: 60px;

      & .mines__decor {
        display: block;
      }

      @media (max-width: 1281px) and (min-width: 1025px) {
        margin-top: -25px;
      }

      @media (max-width: 700px) {
        margin-top: -25px;
      }

      @media (max-width: 521px) {
        margin-top: -20px;
        margin-bottom: 160px;
      }
    }
  }

  &__decor {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: none;
  }

  &__bubble {
    width: 40%;
    height: 70%;
    min-height: 466px;
    min-width: 466px;
    position: absolute;
    z-index: 0;
    &.blue {
      background: radial-gradient(50% 50% at 50% 50%, rgba(75, 104, 255, 0.2) 0%, rgba(75, 104, 255, 0) 100%);
    }
    &.yellow {
      background: radial-gradient(50% 50% at 50% 50%, rgba(232, 225, 63, 0.1) 0%, rgba(232, 225, 63, 0) 100%);
      top: 80px;
      left: 30%;
    }
    &.green {
      background: radial-gradient(50% 50% at 50% 50%, rgba(73, 209, 103, 0.2) 0%, rgba(73, 209, 103, 0) 100%);
      top: 240px;
      right: 0;
    }
  }
}

@media (max-width: 1210px) and (min-width: 1025px),
(max-width: 710px) {
  .none {
    display: none;
  }
}