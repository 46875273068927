.race-game{
    display: flex;
    flex-direction: column;
    &-body{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        height: 135px;
        margin-bottom: 10px;
        overflow: hidden;
        border-radius: 8px;
        &-start, &-end{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #FFC700;
            border-radius: 8px;
            padding: 20px 10px 13px;
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.2);
            .racer-start, .racer-end{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                z-index: 1;
                a{
                    border: 2px solid rgba(255, 255, 255, 0.2);
                    border-radius: 11px;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    padding: 2px;
                    margin-right: 16px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 100%;
                        border-radius: 7px;
                    }
                    &.grey{
                        border-color: #A3A5B7;
                    }
                    &.blue{
                        border-color: #93C7F4;
                    }
                    &.yellow{
                        border-color: #E8E13F;
                    }
                    &.orange{
                        border-color: #E8903F;
                    }
                    &.violet{
                        border-color: #BC93FE;
                    }
                    &.red{
                        border-color: #E8533F;
                    }
                    &.fire{
                        border-color: #FD6228;
                    }
                }
                .info{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    flex-grow: 1;
                    width: 0;
                    span{
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        margin-bottom: 4px;
                        width: 100%;
                        font-weight: 600;
                        white-space: nowrap;
                        flex-shrink: 0;

                    }
                    .nickname{
                        color: #fff;
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        font-weight: bold;
                        flex-grow: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &-bet{
                    display: flex;
                    align-items: center;
                    height: 40px;
                    padding: 0px 16px;
                    background: #1B1F2A;
                    border-radius: 8px;
                    svg{
                        width: 30px;
                        height: 30px;
                        min-width: 30px;
                    }
                    span{
                        color: #FFFFFF;
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
            }
            .helmet{
                position: absolute;
                right: 0px;
                z-index: 0;
            }
            &.me{
                background: #4B68FF;
                border: none;
                .racer-info{
                    span{
                        color: rgba(255, 255, 255, 0.6);
                    }
                    .nickname{
                        color: #fff;
                    }
                }
            }
        }
        &-end {
            background: #1B1F2A;
            border-radius: 8px;
            position: relative;
            .racer-end {
                &-bet {
                    border: 1px solid #F05F31;
                    &.win {
                        border-color: #46BE60;
                        box-shadow: 0px 0px 20px rgba(70, 190, 96, 0.4);
                    }
                }
                .info {
                    & span {
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #FFFFFF;
                    }
                    & .win {
                        background: linear-gradient(133.46deg, #FF5C36 2.62%, #FFBA2D 89.98%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }

                    .nickname{
                        font-weight: 600;
                        color: rgba(255, 255, 255, 0.6);
                        opacity: 0.7;
                    }
                }
            }
            .vs-end{
                position: absolute;
                right: 0;
                top: 0;
                z-index: 0;
                svg {
                    min-width: 71px;
                    min-height: 135px;
                }
            }
        }
        &-current {
            padding: 16px 9px 16px 58px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: #262A37;
            .vs{
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    min-height: 69px;
                    min-width: 42px;}
                }
            .lose {
                opacity: 0.5;
            }
        }
        &-text {
            position: absolute;
            top: 50px;
            left: -25px;
            font-weight: 700;
            font-size: 27.2632px;
            line-height: 37px;
            text-align: center;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.1);
            transform: rotate(-90deg);
        }
    }
    &-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        color: #141720;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 8px;

        &-img {
            margin-right: 8px;
        }

        &-inner {
            margin: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            width: 0;

            &-nickname {
                margin-right: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &.current{
            background-color: #262A37;
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;

        }
        &.me{
            color: #F05F31;
            border: 1px solid #F05F31;
        }
        &.other{
            border: 1px solid #FFC700;
            color: #FFC700;
        }
    }
}
@media screen and (max-width: 1024px) {
    .race-game{
        &-body{
            &-start{
                .helmet{
                    display: none;
                }
            }
        }
        &-body-current{
        }
        &-nav{
            font-size: 0.6rem;
        }
    }
}
