.deposit {
  & .skins {
    padding: 20px 26px;
    background-color: #1B1F2A;
    border-radius: 12px;

    &__title {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 1;
      color: #E8E13F;
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;
    }
  }
}

@media (max-width: 780px) {
  .deposit {
    & .skins {
      &__cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}