.mines-rules {
  position: relative;
  display: grid;
  grid-area: 2 / 1 / 3 / 3;
  padding: 56px 33px;
  background: #1B1F2A;
  border-radius: 12px;

  @media (max-width: 1919px) {
    display: none;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #EAEAEA;
  }

  &__text {
    margin-top: 20px;
    width: 64%;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #7D869E;

    p {
      &:nth-child(2) {
        margin: 15px 0;
      }
    }
  }

  &__list {
    margin-top: 16px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      margin-right: 8px;
      background: rgba(255, 255, 255, 0.03);
      border-radius: 8px;
    }
  }

  &__item {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: #ABB2C7;

    &:nth-child(2) {
      margin: 16px 0;
    }
  }

  &__feature {
    position: relative;
    max-width: 197px;
    padding: 3px 8px 3px 27px;
    margin-top: 27px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #49D167;
    background: #192A27;
    border-radius: 4px;

    &::before {
      content: '';
      height: 14px;
      width: 14px;
      position: absolute;
      top: 4px;
      left: 8px;
      background: url("./../../../assets/mini-deadhead.svg");
      background-repeat: no-repeat;
    }
    
  }
  
  &__decore {
    height: 170px;
    width: 194px;
    position: absolute;
    bottom: 41px;
    right: 46px;
    background: url("./../../../assets/deadhead.svg");
    background-repeat: no-repeat;
  }
}