.mines {
  &__no-games {
    margin: 50px auto;
    width: 297px;
    padding-top: 201px;
    background: url("../../../assets/mines/bg-no-games.png") top center / 252px 197px no-repeat;

    & p {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      text-align: center;
      color: #FFFFFF;

      &:last-child {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.6);

      }
    }
  }

  &__sort {
    margin: 10px 0 15px 0;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    & span {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      cursor: pointer;

      &:first-child {
        color: rgba(255, 255, 255, 0.6);
        cursor: unset;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }

      &.active {
        padding-right: 18px;
        position: relative;
        color: #E8E13F;

        &:after {
          content: '';
          position: absolute;
          background: url('../../../assets/sort-arrows.svg') center no-repeat;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          height: 13px;
          width: 16px;
        }
      }
    }

    
  }
  .sorted .active::after {
    transform: scale(1, -1);
    top: 15%;
  }
}