@import '../../../views/fontSize';

.money {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #141720;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: $textSize;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
  }

  &__label {
    font-size: calc(#{$textSize} - 2px);
    font-weight: 400;
    color: rgba(255, 255, 255, 0.2);
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__coin {
    margin-right: 6px;
  }

  &__num {
    display: inline-block;
    color: #FFFFFF;
    font-size: $numberSize;
    font-weight: 800;
  }
}