.information__svg {
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  opacity: .6;
  transition-duration: .2s;
  &:hover{
    opacity: 1;
  }
}

.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  white-space: pre-line;

  svg {
    margin-left: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    transform: rotateZ(45deg);
    background: #2A2E3D;
    left: calc(50% - 12px);
    border-radius: 2px;
    top: 130%;
    transition-duration: .1s;
    opacity: 0;
    visibility: hidden;
  }

  &__popup {
    position: absolute;
    min-width: 280px;
    max-width: 310px;
    padding: 16px;
    background: #2A2E3D;
    border-radius: 18px;
    border-top: none;
    top: 150%;
    left: -100%;
    transition-duration: .1s;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    transform: translateY(40px);

    .popup {
      &__text {
        color: #C0C6D8 !important;
        font-size: 0.5rem !important;
        font-weight: normal !important;
        line-height: 1.55 !important;
      }
    }
  }
  &:hover {
    border: none;

    &::after {
      opacity: 1;
      visibility: visible;
      transform: rotateZ(45deg) translateY(0px);
    }

    .information {
      &__popup {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        z-index: 5;
      }
    }
  }
}
@media (max-width: 1550px) {
  .information {
    &__popup {
      min-width: 160px;
      max-width: 100px;
      top: 150%;
      left: auto;
    }
  }
}

@media (max-width: 1024px) {
  .information {
    &__popup {
      left: -100%;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-top: 0px;
    }
  }
}