@import "../../fontSize";

.game {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  background: url('../../../assets/bonuses/daily-bg-full.png') no-repeat right top / 405px;

  @media (min-width: 1920px) {
    grid-area: 1 / 3 / 3 / 4;
    width: 100%;
    min-width: 529px;
  }

  @media (max-width: 735px) {
    background: url('../../../assets/bonuses/daily-bg-full.png') no-repeat right top / 300px;
  }

  @media (max-width: 1398px)
  //  and (min-width: 1024px) 
   {
      grid-column-start: 1;
  }

  &__header {
    padding: 30px 35px 0;

    @media (max-width: 480px) {
      & {
        padding: 20px 23px 0;
      }
    }
  }

  &__title {
    color: #E8E13F;
    font-size: $numberSize;
    font-weight: 600;
    margin-bottom: 18px;

    @media (max-width: 480px) {
      & {
        font-size: 22px;
        margin-bottom: 10px;
      }
    }
  }

  &__label {
    color: rgba(255, 255, 255, 0.6);
    font-size: $textSize;
    margin-bottom: 7px;

  }

  &__attempts {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-left {
      background-color: #141720;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      width: 30px;
      height: 13px;
      margin-right: 6px;
      transition: .2s all ease;

      &-active {
        background-color: #E8E13F;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    & .game__button {
      color: #FFF;
      font-size: $textSize;
      font-weight: 700;
      padding: 8px 30px;
      background-color: #141720;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      margin-right: 10px;
      transition: .2s all ease;

      &:hover {
        color: #FFF;
        background-color: rgba(232, 225, 63, 0.30);
        border: 1px solid rgba(232, 225, 63, 0.30);
      }

      &--active,
      &:focus {
        color: #1B1F2A;
        background-color: #E8E13FFF;
        border: 1px solid #E8E13FFF;
      }
    }
  }

  .game__field {
    padding: 30px 35px;
    position: relative;
    display: grid;
    gap: 3%;
    row-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .game__card:nth-child(n + 26) {
      display: none;
    }

    @media (min-width: 1920px) {
      padding: 30px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(6, 1fr);

      .game__card:nth-child(n + 26) {
        display: block;
      }
    }

    @media (max-width: 1398px) {
      padding: 30px 0;
      max-width: 440px;
      margin: 0 auto;
    }

    @media (max-width: 1150px) {
      max-width: 330px;
    }

    @media (max-width: 1024px) {
      max-width: 380px;
    }
    
    @media (max-width: 480px) {
      max-width: 300px;
    }
  }
}