.bonuses__card-achievements {
  background: url('../../../../assets/bonuses/achiev-bg.png') no-repeat 25% 15% / 520px,
              url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / cover,
              #4D6AFF;
  overflow: visible;
  .card__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .card__amount {
    display: flex;
    margin-left: 15px;
    position: relative;
    padding-left: 25px;
    &:before {
      content: url('../../../../assets/bonuses/crown.svg');
      position: absolute;
      width: 20px;
      height: 20px;
      top: -8px;
      left: -10px;
    }
    & span {
      color: #FFF;
    }
  }
  .crown-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    & .top-1,
    & .top-2,
    & .top-3 {
      width: 0;
      height: 0;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      border-top: 10px solid #4D6AFF ;
      position: absolute;
    }
    & .top-1 {left: 3px;}
    & .top-2 {left: 8px;}
    & .top-3 {left: 13px;}

    & .middle {
      width: 18px;
      height: 14px;
      background-color: #ffc700;
      border-radius: 1px 1px 0 0;
    }
    & .bottom{
      width: 22px;
      height: 3px;
      background-color: #ffdc5e;
      border-radius: 10px;
    }
  }
}

@media (max-width: 1219px) {
  .bonuses__card-achievements {
    background: url('../../../../assets/bonuses/achiev-bg.png') no-repeat right -50px bottom -75px / 500px,
    url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
    #4D6AFF;
  }
}
@media (max-width: 1024px) {
  .bonuses__card-achievements {
    background: url('../../../../assets/bonuses/achiev-bg.png') no-repeat right -50px bottom -75px / 500px,
    url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
    #4D6AFF;
  }
}
@media (max-width: 768px) {
  .bonuses__card-achievements {
    background: url('../../../../assets/bonuses/achiev-bg.png') no-repeat right -35px bottom -65px / 350px,
    url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
    #4D6AFF;
  }
}