.mobile-list {
  height: 0px;
  min-width: 345px;
  display: block;
  background-color: #1B1F2A;
  border-radius: 12px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-70%);
  transition: all 0.3s ease;

  &.active {
    margin-bottom: 16px;
    height: auto;
    opacity: 1;
    transform: translateY(0);
  }

  .bet-item {
    padding: 8px 0;
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;

    & .bets-avatar {
      padding: 1px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      margin-right: 6px;
      border-radius: 8.25px;
      background-color: #1B1F2A;

      .level {
        width: 14px;
        height: 14px;
        font-weight: 700;
        font-size: 8px;
      }
    }

    & span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }

    & .bet-value {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: #2A2E3D;
      }
    }

    &.mobile-is-me {
      margin-left: 0px;
      margin-right: 0px;
      padding: 8px;
      background: lighten(#1B1F2A, 10%);
      &:after {
        content: '';
        display: none;
      }
    }
  }
}

@media (min-width: 1430px) {
  .mobile-list {
    min-width: auto;
    max-width: 100%;
    width: 100%;
    height: auto;
    opacity: 1;
    flex: 1 1 auto;
    transform: translateY(0);

    .bet-item {
      padding: 8px 0;
      margin-left: 8px;
      margin-right: 8px;
      position: relative;
      display: flex;
      align-items: center;

      & span {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}