.card {

  &__round {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);

    @media (max-width: 1282px) and (min-width: 1024px),
    (max-width:700px) {
      margin: 17px 0;
    }

    @media (max-width: 1280px) and (min-width: 1024px),
    (max-width:700px) {
      grid-area: 2 / 1 / 3 / 3;
      display: flex;
      left: 50%;
      align-items: center;
      height: 30px;

    }

    & p {
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      white-space: nowrap;
      order: 1;

      @media (max-width: 1280px) and (min-width: 1024px),
      (max-width:700px) {
        margin: 0;
      }
    }

    &.inf {
      align-self: flex-end;
    }

    & .waiting {
      margin-left: 20px;
    }
  }

  &__game-code {
    padding: 6px 10px;
    position: absolute;
    right: 80px;
    top: -50px;
    transform: translateX(-50%);
    white-space: nowrap;
    background: rgba(75, 104, 255, 0.1);
    border: 1px solid #4B68FF;
    border-radius: 20px;
    font-weight: 600;
    font-size: 12px;
    color: #4B68FF;
    
    @media (max-width: 1100px) and (min-width:1024px) {
      right: 20%;
      top: -40px;
    }

    // @media (max-width: 1281px) and (min-width: 1024px) {
    //   top: -134px;
    // }

    // @media (max-width: 1150px) and (min-width: 1024px) {
    //   top: -112px;
    // }

    // @media (max-width: 700px) {
    //   top: -135px;
    // }

    @media (max-width: 520px) {
      display: none;
    }
  }

  &__map {
    position: absolute;

    @media (max-width: 1280px) and (min-width: 1024px),
    (max-width:700px) {
      position: relative;
      order: 3;
      left: 0px;
      top: -10px;
    }

    &.tree {
      top: -19px;
      left: -4px;

      @media (max-width: 1340px) {
        left: -6px;
      }

      @media (max-width: 1300px) {
        left: -15px;
      }

      @media (max-width: 1280px) and (min-width: 1024px),
      (max-width:700px) {
        top: 7px
      }
    }

    &.csgo {
      top: -4px;
      left: -16px;

      @media (max-width: 1340px) {
        left: -25px;
      }

      @media (max-width: 1300px) {
        left: -35px;
      }

      @media (max-width: 1280px) and (min-width: 1024px),
      (max-width:700px) {
        left: 0px;
        top: 2px;
      }
    }

    &.cactus {
      top: -16px;
      left: 0px;

      @media (max-width: 1300px) {
        left: -10px;
      }

      @media (max-width: 1280px) and (min-width: 1024px),
      (max-width:700px) {
        top: 8px;
      }
    }

    &.inf {
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__row {
    display: flex;
    gap: 3px;
    width: 156px;

    @media (max-width: 1280px) and (min-width: 1024px),
    (max-width:700px) {
      order: 2;
      margin: 0 30px;
    }

    @media (max-width: 1340px) {
      width: 136px;
    }

    @media (max-width: 1300px) {
      width: 116px;
    }

    & span {
      width: 10%;
      display: block;
      height: 2px;
      flex: 1 1 100%;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.infinity {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4.5px;
      font-weight: 600;
      font-size: 12px;
      color: #FFFFFF;
    }

    & .passed {
      background-color: #4B68FF;
    }
  }
}