.race-track {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto 0;
  transition-duration: 1s;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  max-height: 0px;

  svg {
    width: 100%;
    height: auto;
  }

  #green-car {
    width: 100px;
    height: 100px;
    background-color: green;
  }

  #blue-car {
    width: 100px;
    height: 100px;
    background-color: blue;
  }

  &.open {
    max-height: 2000px;
  }

  &-header {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 4;

    .header-racer{
      position: relative;
      display: flex;
      flex-direction: column;
      width: 220px;
      min-width: 220px;
      padding-left: 20px;
      padding-top: 5px;
      background: rgba(34, 37, 45, 0.95);
      box-shadow: 0px 0px 30px rgba(46, 48, 56, 0.5);
      border-radius: 12px;
      @media (max-width: 1130px) and (min-width: 1024px) {
        width: 180px;
        min-width: 180px;
      }
      @media (max-width: 500px) {
        width: 150px;
        min-width: 150px;
      }
      &-header{

        span:first-child{
          position: absolute;
          font-weight: 800;
          font-size: 22px;
          line-height: 30px;
          text-transform: uppercase;
          color: #FFFFFF;
          &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            height: 57px;
            width: 20px;
            background: url("../../../assets/race/arrows.svg") no-repeat;
            background-size: contain;
          }

          & .lose {
            color: #E8533F;
          }

          & .win {
            color: #49D167;
          }
        }


      }

      &-body {
        padding: 15px 20px 20px 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img{
          width: 32px;
          height: 32px;
          border-radius: 8px;
        }
        span{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
        }
      }
      &-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 5px;
        width: 0;
        flex-grow: 1;
        overflow-x: hidden;
        & .bet{
          display: flex;
          align-items: center;
          gap: 3px;

          img {
            width: 18px;
            height: 18px;
          }
          span{
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;
          }

          .disabled {
            display: none;
          }
        }
      }
      &.blue{
        background: rgba(42, 36, 182, 0.8);
      }
      &.red{
        background: linear-gradient(180deg, rgba(232, 83, 63, 0.85) 0%, rgba(232, 83, 63, 0.85) 100%);
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .race-track {
    overflow: visible;

    svg {
      border-radius: 16px;
    }

    &-header {
      position: absolute;
      top: -110px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .header-racer {
        width: calc(50% - 6px)
      }
    }

    &.open {
      margin-top: 120px;
    }
  }
}


@media (max-width: 425px) {
  .race-track {

    &.open {
      margin-top: 90px;
    }

    &-header {
      top: -80px;

      .header-racer {
        padding: 0;

        &-body {
          padding: 15px;
        }

        & span:first-child {
          display: none;
        }
      }
    }
  }
}
