.private {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(51, 57, 74, 0.55), rgba(51, 57, 74, 0.55));
  z-index: 12;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__popup {
    padding: 30px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 413px;
    height: 324px;
    background-color: #1B1F2A;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 11;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    gap: 15px;

    & span {
      font-weight: 800;
      font-size: 26px;
      color: #FFFFFF;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__form {
    margin-top: 23px;
  }

  &__input {
    margin-bottom: 35px;
    margin-left: 0;
    padding: 17px 27.5px;
    width: 100%;
    background: rgba(51, 57, 74, 0.5);
    border: 1px solid #33394A;
    border-radius: 12px;
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #fff;
  }

  &__button {
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E8E13F;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    color: #141720;
  }

}