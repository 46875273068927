.timer-component {
  display: flex;
  font-size: 14px;
  color: #FFFFFF;
  //max-width: max-content;
  width: 120px;
  margin-top: 10px;
  padding: 9px 16px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.07);
  &__num {
    display: flex;
    width: 30px;
    
  }
}