.crash {
  display: flex;
  flex-direction: column;

  .crash-rates-input {
    position: relative;
    widht: 100%;
    flex: 1 0 auto;
  }

  .crash-rates-body-clear {
    position: absolute;
    border-radius: 6px;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    margin-right: 5px;
    padding: 5px 9px;
    font-size: 0.6rem;
    line-height: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition-duration: .2s;


    &:hover {
      background: rgba(240, 95, 49, 0.2);
    }
  }

  .game-header .information {
    display: none;
  }

  .autocash-out-tab {
    cursor: pointer;
  }

  &-recent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    margin-bottom: 6px;

    @media (max-width: 1250px) {
      margin-top: 16px;
    }

    .recent-header {
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.6rem;
      line-height: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 9px;
    }

    .recent-body {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      width: 100%;
      overflow: hidden;
      margin-right: 0px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background: rgb(20, 23, 32);
        background: linear-gradient(90deg, rgba(20, 23, 32, 0.0984768907563025) 0%, rgba(20, 23, 32, 0.7203256302521008) 35%, rgba(20, 23, 32, 1) 100%);
      }

      &-item {
        border-radius: 8px;
        height: 27px;
        min-height: 27px;
        padding: 0px 8px;
        margin-right: 8px;
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #FFFFFF;

        &.grey {
          background: rgba(#757575, 0.1);
          border: 1px solid #757575;
        }

        &.lime {
          background: rgba(70, 190, 96, 0.1);
          border: 1px solid #46BE60;
        }

        &.blue {
          background: rgba(75, 104, 255, 0.1);
          border: 1px solid #4B68FF;
        }

        &.violet {
          background: rgba(#7B61FF, 0.1);
          border: 1px solid #7B61FF;
        }

        &.gold {
          background: rgba(232, 225, 63, 0.1);
          border: 1px solid #E8E13F;
        }
      }
    }
  }

  &-row {
    margin-top: 16px;
    display: flex;
    gap: 16px;
  }


  &-bets {
    padding: 24px;
    background-color: rgba(27, 31, 42, 0.5);
    border-radius: 12px;
    width: 100%;

    &.disable input {}

    &.disable {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 12px;
        background: rgba(27, 31, 42, 0.5);
        z-index: 3;
      }
    }

    .crash-rates-mobile {
      display: none;
    }

    .bets-header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.6rem;
      line-height: 0.8rem;
      font-weight: 700;

      & .information .information__popup {
        left: -205px;
        min-width: 240px;
      }

      .turn {
        display: flex;
        align-items: center;
        cursor: pointer;

        &-title {
          margin-right: 14px;
        }

        &-thumb {
          background: rgba(255, 255, 255, 0.08);
          border-radius: 16px;
          border: 0.3px solid transparent;
          width: 31px;
          height: 18px;
          position: relative;
          display: flex;
          align-items: center;

          span {
            width: 14px;
            height: 14px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.2);
            position: absolute;
            left: 1px;
            transition-duration: .2s;
          }
        }

        &.active {
          .turn-thumb {
            border: 0.3px solid #46BE60;

            span {
              left: 14px;
              background: #46BE60;
            }
          }
        }
      }
    }

    .bet-amount {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.6);

      }

      input {
        padding-left: 15px;
        height: 44px;
        width: 100%;
        color: #FFFFFF;
        font-size: 0.9rem;
        line-height: 1.2rem;
        font-weight: bold;

        &:focus::-webkit-input-placeholder {
          color: transparent;
        }

        &.disable-input {
          color: rgba(255, 255, 255, 0.6) !important;
        }
      }

      .minmax-bets {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        margin-top: 12px;

        span {
          text-transform: uppercase;

          &:first-child {
            margin-right: 20px;
          }

          span {
            margin-left: 7px;
            color: #FFFFFF;
            font-size: 0.7rem;
            font-weight: bold;
            line-height: 1rem;
          }
        }
      }
    }

    .autocash-out {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
        ::placeholder {
          padding: 16px 16px 16px 0;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #494C55;
        }

      input {
        padding-left: 15px;
        background: #1B1F2A;
        border: 1px solid #2A2E3D;
        border-radius: 8px;
        height: 44px;
        color: #FFFFFF;
        font-size: 0.9rem;
        line-height: 1.2rem;
        font-weight: bold;
        transition-duration: .2s;


        &:disabled {
          opacity: 0.7;
        }
      }

      &-tabs {
        display: flex;
        gap: 2px;
        font-weight: 700;
        font-size: 10px;
        color: #FFFFFF;

        .turn {
          &-title {
            margin-right: 4px;
          }

          &-thumb {
            width: 25px;
            height: 15px;

            span {
              width: 11px;
              height: 11px;
            }
          }

          &.active {
            .turn-thumb {
              span {
                left: 11px;
              }
            }
          }
        }
      }

      &-tab {
        padding: 4px 7.6px;
        display: flex;
        align-items: center;
        gap: 3px;
        background-color: #1B1F2A;
        border: 1px solid #2A2E3D;
        border-radius: 6px;

        &.active {
          background-color: rgba(75, 104, 255, 0.1);
          border: 1px solid #4B68FF;
          border-radius: 6px;
        }
      }
    }

    .place-bet {
      padding: 4px 4px 4px 15px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      font-size: 0.7rem;
      z-index: 9;

      &-bet {
        padding: 6px 16px;
        display: flex;
        align-items: center;
        gap: 3px;
        color: #FFC700;
        background-color: #1B1F2A;
        border-radius: 8px;
      }

      &.cash-out {
        background-color: #4DDB6C;
      }
    }
  }

  &-rates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &-body {
      position: relative;
      margin-bottom: 40px;
      background: #1B1F2A;
      border: 1px solid #2A2E3D;
      border-radius: 8px;
      display: flex;
      align-items: center;
    ::placeholder {
      padding: 16px 16px 16px 0;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #494C55;
    }
      &-list {
        width: 100%;
        position: absolute;
        top: 60px;
        display: flex;
        align-items: center;
        margin-right: 9px;

        li {
          width: 20%;
          height: 29px;
          border: 1px solid #2A2E3D;
          background: #1B1F2A;
          border-radius: 8px;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition-duration: .2s;

          &:not(:last-child) {
            margin-right: 5px;
          }

          &:hover {
            background: rgba(75, 104, 255, 0.1);
            border: 1px solid #4B68FF;
          }
        }
      }

      &-clear {
        border-radius: 20px;
        padding: 0px 16px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #F05F31;
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;
        cursor: pointer;
      }
    }

    &-profit {
      display: flex;
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.6rem;
      line-height: 0.8rem;
      align-items: center;

      span {
        color: #FFFFFF;
        font-size: 0.8rem;
        line-height: 1.1rem;
        font-weight: bold;
        margin-left: 100px;
      }
    }
  }

  &-table {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-top: 25px;

    .header {
      margin-bottom: 8px;
      padding: 0px 0px 0px 8px;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      background-color: transparent;
      border: none;
      height: auto;

      span {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 700;
        font-size: 12px;

        &:not(:first-child) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .user {
      position: relative;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      height: 50px;
      width: 100%;
      border-radius: 12px;
      padding-left: 7px;
      background: #1B1F2A;

      div {
        &:not(:first-child) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-data {
        display: flex;
        align-items: center;

        a {
          position: relative;
          display: flex;
          align-items: center;
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 11px;
          width: 40px;
          height: 40px;
          padding: 2px;
          margin-right: 15px;

          img {
            width: 100%;
            border-radius: 7px;

            &.streamer {
              filter: blur(5px);
            }
          }

          &.grey {
            border-color: #A3A5B7;
          }

          &.blue {
            border-color: #93C7F4;
          }

          &.yellow {
            border-color: #E8E13F;
          }

          &.orange {
            border-color: #E8903F;
          }

          &.violet {
            border-color: #BC93FE;
          }

          &.red {
            border-color: #E8533F;
          }

          &.fire {
            border-color: #FD6228;
          }
        }

        span {
          color: #FFFFFF;
          font-size: 0.7rem;
          line-height: 1rem;
          margin-right: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 180px;

          @media (max-width: 1350px) and (min-width: 1024px) {
            display: none;
          }
        }

        .level {
          padding: 0;
          position: absolute;
          bottom: 0;
          left: 20.5px;
          max-height: 14px;
          min-width: 14px;
          border-radius: 50%;
          color: #000000;
          font-size: 8px;
          background-color: rgba(#fff, .1);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          &.grey {
            background: #A3A5B7;
          }

          &.blue {
            background: #93C7F4;
          }

          &.yellow {
            background: #E8E13F;
          }

          &.orange {
            background: #E8903F;
          }

          &.violet {
            background: #BC93FE;
          }

          &.red {
            background: #E8533F;
          }

          &.fire {
            background: linear-gradient(82.31deg, #FD6228 67.56%, #FFBA2D 111.37%);
          }
        }
      }

      &-cashout {
        color: #FFFFFF;
        font-size: 0.9rem;
        line-height: 0.9rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        align-self: center;
        justify-self: center;
        width: 88px;
        height: 28px;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.02);
        border-radius: 42px;

        .user-cashout-val {
          display: flex;
          align-items: center;
          align-self: center;
          justify-self: center;
        }

        & .disable {
          display: none;
        }

        &.red {
          color: #D7392F;
        }
      }

      &-bet,
      &-profit,
      &-cashout {
        color: #fff;
        font-size: 0.7rem;
        line-height: 0.8rem;
        font-weight: bold;
        display: flex;
        align-items: center;

        &.red {
          color: #D7392F;
        }

        svg, img {
          margin-right: 8px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.is-me {
        background: rgba(255, 255, 255, 0.05);

      }
    }
  }
}

@media (max-width: 1540px) and (min-width: 1350px) {
  .crash-rates {
    &-body {
      &-list {
        & li {
          // padding: 0 12px;
          // width: 32px;
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: 1350px) and (min-width: 1024px),
(max-width: 867px) {
  .crash {

    &-row {
      flex-direction: column;
    }

    &-rates {
      margin-top: 10px;
      gap: 10px;
      flex-direction: column;

      &-body {
        position: relative;
        width: 100%;

        &-list {
          margin-right: 0;
          flex: 1 1 auto;

          & li {
            padding: 0 6px;
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .crash {
    &-recent {
      margin-top: 16px;
      margin-bottom: 40px;

      .recent-header {
        font-size: 0.5rem;
        line-height: 0.7rem;
        margin-bottom: 5px;
      }

      .recent-body {
        padding-bottom: 4px;

        &::-webkit-scrollbar {
          height: 1px;
        }

        &-item {
          margin-right: 5px;
        }
      }
    }

    .timer {
      top: 80px;
    }

    &-bets {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      background: #1B1F2A;
      border-radius: 8px;
      width: 100%;
      padding: 14px;
      grid-column-gap: 0px;

      .crash-rates-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 8px;
        position: relative;

        .crash-rates {
          &-body {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 18px;

            &-list {
              width: 100%;
              margin-right: 0px;

              li {
                height: 35px;
                flex: 1;
                padding: 0px 8px;
                margin-right: 7px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }

            &-clear {
              padding: 0px 13px;
              height: 22px;
              font-size: 0.5rem;
              line-height: 0.7rem;
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
          }

          &-profit {
            font-size: 0.5rem;
            line-height: 0.7rem;

            span {
              font-size: 0.7rem;
              line-height: 1rem;
              margin-left: 10px;
            }
          }
        }
      }

      .bets-header {
        font-size: 0.5rem;
        line-height: 0.7rem;
        margin-bottom: 8px;
        height: 20px;
      }

      .bet-amount {
        width: 100%;

        span {
          font-size: 0.5rem;
          line-height: 0.7rem;
          height: 20px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
        }

        .minmax-bets {
          display: none;
        }
      }

      .autocash-out {
        width: 100%;
      }

      .place-bet {
        width: 100%;
        height: 44px;
        margin-top: 20px;
      }
    }

    &-rates {
      &-body-list {
        & li {
          padding: 0 8px;
        }
      }
    }

    &-table {
      & .user {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 3px;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .crash-rates-body {
    position: relative;

    &-list {
      margin-right: 0;

      & li {
        padding: 0 6px;
        width: 35px;

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }

  .crash-bets .bet-amount input {
    max-width: 70px;
    font-size: 0.8rem;
  }
}

@media (max-width: 867px) {
  .crash {
    &-table {
      .header {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .user {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &-data {
          & a {
            margin-right: 5px;
          }

          span {
            display: none;
          }
        }
      }
    }
  }
}
