.deposit {
  & .crypto {
    padding: 20px 25px;
    background-color: #1B1F2A;
    border-radius: 12px;
    font-size: 12px;

    &__title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.375;
      color: #E8E13F;
    }

    &__cards {
      margin-bottom: 43px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 12px;
    }

    &__buy {
      padding-left: 152px;
      padding-bottom: 10px;
      background: url("../../assets/deposit/crypto.png") left 20px center / contain no-repeat;

      & p {
        margin-bottom: 16px;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.6);

        &:first-child {
          margin-bottom: 8px;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
    }

    &__list {
      display: flex;
      align-items: center;
      gap: 16px;

      & a {
        font-weight: 500;
        line-height: 1.5;
        color: #FFFFFF;
        transition: all .3s ease;

        & svg {
          stroke: #fff;
          transition: all .3s ease;
        }

        &:hover {
          color: #49D167;

          & svg {
            stroke: #49D167;
          }
        }
      }
    }

    &__arrow {
      margin-left: 10px;
    }
  }
}

@media (max-width: 1340px) and (min-width: 1024px), (max-width: 767.9px) {
  .deposit {
    & .crypto {
      &__cards {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media (max-width: 1250px) and (min-width: 1024px), (max-width: 650px) {
  .deposit {
    & .crypto {
      &__cards {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media (max-width: 1100px) and (min-width: 1024px) {
  .deposit {
    & .crypto {
      &__buy {
        padding-left: 95px;
        background: url("../../assets/deposit/crypto.png") left center / 15% no-repeat;
      }
    }
  }
}

@media (max-width: 490px) {
  .deposit {
    & .crypto {
      padding: 15px;

      &__buy {
        padding-left: 0px;

        & p {
          padding-left: 60px;
        }
        background: url("../../assets/deposit/crypto.png") left top/55px no-repeat;
      }

      &__list {
        justify-content: space-around;
      }
    }
  }
}