.mines-game-over {
  position: absolute;
  min-width: 267px;
  top: 34%;

  @media (max-width: 520px) {
    top: 15%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 80px;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__card {
    margin-bottom: 7px;
    padding: 17px 0 15px 0;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: url('../../../assets/game-over.png') no-repeat bottom -50px center,
            #1B1F2A;
  }

  &__text {
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 30.75px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFC700;
    text-shadow: 0px 0px 10px rgba(255, 199, 0, 0.5);
  }

  &__winner {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .bets-avatar {
      margin-right: 8px;
    }
  }

  &__nickname {
    max-width: 190px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    overflow: hidden;
  }

  &__bet {
    margin: 0 auto;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;

    & svg {
      margin-right: 5px;
      width: 13px;
      height: 13px;
    }

    & span {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #fff;
    }
  }

  &__create {
    margin-bottom: 7px;
    padding: 9px 0px;
    width: 100%;
    background-color: #46BE60;
    border-radius: 8px;

    & span {
      font-weight: 800;
      font-size: 10px;
      line-height: 14px;
      color: #1B1F2A;
    }
  }

  &__back {
    padding: 9px 0px;
    width: 100%;
    background-color: #2A2E3D;
    border-radius: 8px;
    text-align: center;

    & span {
      font-weight: 800;
      font-size: 10px;
      line-height: 14px;
      color: #FFFFFF;
    }
  }
}