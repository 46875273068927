.game {

  &__card {
    width: 65px;
    height: 65px;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    
    // @media (min-width: 1600px) {
    //   & {
    //     width: 65px;
    //     height: 65px;
    //   }
    // }
    // @media (min-width: 1700px) {
    //   & {
    //     width: 80px;
    //     height: 80px;
    //   }
    // }

    @media (min-width: 1920px) {
      width: 66px;
      height: 66px;
    }


    @media (max-width: 1398px) and (min-width: 1025px) {
      height: 75px;
      width: 75px;
    }

    @media (max-width: 1150px) and (min-width: 1025px) {
      height: 58px;
      width: 58px;
    }
    @media (max-width: 1025px)  {
      height: 65px;
      width: 65px;
    }
    @media (max-width: 485px) {
      width: 52px;
      height: 52px;
    }



    &-shirt {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url("../../../assets/bonuses/daily-shirt.png") no-repeat center center / contain;
      backface-visibility: hidden;
      transition: .3s all ease;
      transform: rotateY(0deg);
    }

    &-face {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      color: #F8C921;
      font-size: 13px;
      font-weight: 900;
      // padding: 10px 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      backface-visibility: hidden;
      transition: .3s all ease;
      transform: rotateY(180deg);

      &-fail {
        background: url("../../../assets/bonuses/daily-face-fail.png") no-repeat center center / contain;
      }

      &-prize {
        background: url("../../../assets/bonuses/daily-face-prize.png") no-repeat center center / contain;

        & span {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1B1F2A;
          border-radius: 30px;
          width: 58px;
          height: 20px;
          margin-bottom: 4px;

          @media (max-width: 481px) {
            height: 15px;
            font-size: 10px;
            margin-bottom: 2px;
          }

        }
      }
    }
  }

  &__card-active {
    .game__card-shirt {
      color: crimson;
      transform: rotateY(180deg) !important;
    }

    .game__card-face {
      transform: rotateY(0deg) !important;
    }
  }
}