.daily {
  &__header {
    display: flex;
    align-items: start;
    margin-bottom: 23px;
  }

  &__back {
    margin-right: 12px;
    margin-top: 4px;
  }

  &__content {
    display: grid;
    gap: 10px;
    //grid-template-rows: 260px 1fr;
    grid-template-rows: 280px 1fr;
    grid-template-columns: 1fr 430px;

    @media (min-width: 1920px) {
      & {
        grid-template-columns: 386px 1fr 529px;
        grid-template-rows: 332px 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
      }

      .depend {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    @media (max-width: 1398px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-gap: 0;
      gap: 0;
      grid-row-gap: 10px;
    }


    & > div {
      background-color: #1B1F2A;
      border-radius: 12px;
    }

    & .testlevel {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  & .game-header {
    padding-top: 0;
    height: 33px;
  }
}

@media (max-width: 1400px) {
  .lock {
    width: calc(100% + 30px);
    left: -15px
  }
}