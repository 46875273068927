.skins__card {
  transition: all .3s ease;
  cursor: pointer;

  .skins__game {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    border-radius: 12px 12px 0 0;
    background: url("../../../assets/deposit/bg-card.svg") left center / contain no-repeat, #262A37;

    @media (max-width: 780px) {
      background-position: center;
    }

    img {
      max-width: 97px;
    }
  }

  .skins__name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    background: rgba(73, 209, 103, 0.04);
    border-radius: 0 0 12px 12px;
    min-height: 40px;

    img {
      max-width: 97px;
    }
  }
}