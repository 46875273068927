.profile-rewards {
    width: 100%;
    position: relative;
    overflow-y: visible;
    padding-bottom: 4px;
    margin-top: 37px;

    & .profile-rewards-coin {
        display: inline-block !important;
    }

    &-item {
        margin: 0 10px;
        min-width: 131px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        height: 175px;

        .rewards-front {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-items: flex-start;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: #4B68FF;
            border-radius: 12px;
            padding: 10px 14px;
            overflow: hidden;

            &.block {
                background: #1B1F2A;

                .rewards-front {
                    &-header {
                        opacity: 0;
                    }

                    &-name {
                        opacity: 0;
                    }

                    &-block {
                        .rewards-front-header {
                            opacity: 1;
                        }

                        .rewards-front-name {
                            opacity: 0.5;
                        }
                    }

                    &-img {
                        filter: blur(3px);
                        opacity: 0.5;
                    }
                }
            }

            &-header {
                display: flex;
                width: 100%;
                margin-bottom: 4px;
                align-items: center;
                justify-content: space-between;

                span {
                    display: flex;
                    align-items: center;
                    color: #E8E13F;
                    font-size: 0.6rem;
                    line-height: 0.8rem;
                    font-weight: bold;

                    svg, img {
                        margin-right: 4px;
                    }
                }

                svg {
                    cursor: pointer;
                }
            }

            &-name {
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }

            &-img {
                position: absolute;
                height: 120px;
                width: auto;
                top: 54px;
                right: -18px;
            }

            &-block {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 10px 14px;
                padding-bottom: 14px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 12px;

                .rewards-front-header {
                    z-index: 2;

                    span {
                        opacity: 0.5;
                    }
                }

                .rewards-front-name {
                    opacity: 0.5;
                }

                &-image {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                }

                &-progress {
                    position: relative;
                    background: #484A4D;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
                    border-radius: 50px;
                    width: 100%;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(#fff, .5);
                    font-size: 0.5rem;
                    line-height: 0.6rem;
                    z-index: 2;
                }

                &-pickup {
                    background: rgba(27, 31, 42, 0.4);
                    border: 1px solid #BEF630;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    color: #BEF630;
                    font-size: 0.5rem;
                    line-height: 0.7rem;
                    font-weight: bold;
                    height: 33px;
                    width: 100%;
                    min-height: 33px;
                    z-index: 2;
                }

                &-background {
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                }
            }
        }

        .rewards-back {
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(180deg);
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-items: flex-start;
            background: #1B1F2A;
            border: 1px solid #4D6AFF;
            border-radius: 12px;
            padding: 10px 14px;
            overflow: hidden;

            &-header {
                display: flex;
                width: 100%;
                margin-bottom: 4px;
                align-items: center;
                justify-content: space-between;
                z-index: 2;

                span {
                    display: flex;
                    align-items: center;
                    color: #E8E13F;
                    font-size: 0.6rem;
                    line-height: 0.8rem;
                    font-weight: bold;

                    svg, img {
                        margin-right: 4px;
                    }
                }

                .close {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: rgba(#fff, .5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 80%;

                        path {
                            fill: #1B1F2A;
                        }
                    }
                }
            }

            &-name {
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }

            &-text {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.55rem;
                line-height: 0.7rem;
                margin-top: 5px;
                height: 100%;
            }

            &-body {
                display: flex;
                align-items: center;
            }

            &-ps {
                color: rgba(255, 255, 255, 0.4);
                font-size: 0.45rem;
                line-height: 0.6rem;
                margin-bottom: 8px;
            }

            &-progress {
                background: #484A4D;
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
                border-radius: 50px;
                width: 100%;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(#fff, .5);
                font-size: 0.5rem;
                line-height: 0.6rem;
                z-index: 2;
            }

            &-background {
                position: absolute;
                right: 0px;
                bottom: 0px;
            }
        }

        &-content {
            perspective: 1000;

            &.info {
                .profile-rewards-item {
                    transform: rotateY(180deg);
                }
            }

            &:last-child {
                .profile-rewards-item {
                    .rewards-front-img {
                        margin-bottom: -14px;
                    }
                }
            }
        }

        .rewards-progress {
            position: relative;
            color: #919294;

            // color: #BEF630;
            &__value {
                text-align: center;
                z-index: 10;
                width: 100%;
                -webkit-background-clip: text !important;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__line {
        z-index: 0;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        max-width: 100%;
        background: linear-gradient(90deg, #86EC27 0.03%, #EFFF37 99.97%);
        border-radius: 50px;
    }
}


.slick-arrow {
    position: absolute;
    top: -56px;
    width: 36px;
    height: 36px;
    background: #2A2E3D;
    border-radius: 8px;

    &::after {
        content: " ";
        height: 24px;
        width: 24px;
        position: absolute;
        top: 10px;
        left: 10px;
        background: url('./../../src/assets/reward/arrow.svg') no-repeat;
    }
}

.slick-prev {
    right: 60px;
    font-size: 0px;

    &::after {
        transform: rotate(180deg);
        top: 2px;
        left: 2px;
    }
}

.slick-next {
    right: 6px;
    font-size: 0px;
}

.slick-dots {
    display: none !important;
}

@media screen and (max-width: 1024px) {
    .profile-rewards {
        width: 100%;
        padding-bottom: 0px;
        margin-top: 60px;


        &::-webkit-scrollbar {
            display: none;
        }
    }
}