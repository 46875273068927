.bonuses__card-rakeback {
  background:
    // url('../../../../assets/bonuses/rakeback-bg.png') no-repeat right bottom,
    url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / cover,
    #1B1F2A;

  & .card__text {
    margin-bottom: 20px;
  }

  & .card__image {
    position: absolute;
    bottom: -33px;
    right: -52px;
    max-width: 304px;

    @media (max-width: 735px) {
      bottom: -31px;
      right: -35px;
      max-width: 214px;
    }
    
  }
}

@media (max-width: 1024px) {
  .bonuses__card-rakeback {
    background:
      url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
      #1B1F2A;

    & .card__text {
      margin-bottom: 10px;
    }

    & .card__money {
      padding: 3px 0;
    }
  }
}

@media (max-width: 767.9px) {
  .bonuses__card-rakeback {
    background: 
      url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
      #1B1F2A;
  }
}