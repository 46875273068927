.maps {

  grid-area: 2 / 1 / 3 / 3;
  padding: 20px;
  background: #1B1F2A;
  border-radius: 12px;
  color: rgb(255, 255, 255);

  @media (min-width: 1919px) {
    grid-area: 1 / 2 / 2 / 4;
    max-height: 242px;
  }

  button {
    &:hover {
      transform: translateY(-10px);
    }
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    @media (max-width:440px) {
      font-size: 14px;
    }
  }

  &__area {
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 1.6px;

    &::-webkit-scrollbar {
      height: 4px;
      background-color: rgba(#fff, .2);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#fff, .4);
      border-radius: 4px;
    }
  }

  &__block {
    position: relative;
    overflow: hidden;
    height: 163px;
    width: 134px;
    background: #202331;
    margin-right: 12px;
    border-radius: 13px;
    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;

    &:hover {
      background: #32353F;

      .maps__type {
        color: #EAEAEA;
      }
    }

    @media (min-width: 1919px) {
    }
  }

  &__image {
    max-width: 100%;
    outline: 4px solid #1B1F2A;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    user-select: none;
    
  }

  &__button {
    margin-top: 10px;
    border-radius: 12px;
    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;

  }


  &__type {
    display: flex;
    margin-top: 7%;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ABB2C7;
    -webkit-transition: all 0.3s 0s ease;
    -moz-transition: all 0.3s 0s ease;
    -o-transition: all 0.3s 0s ease;
    transition: all 0.3s 0s ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .active {
    // border-color: #E8E13F;
    transform: translateY(-10px);

    .maps__block {
      background-color: #C9D1FF;
    }

    .maps__type {
      color: #1B1F2A;
    }
  }

  .soon {
    .maps__type {
      color: #494C55;
    }

    .maps__block {
      &:hover {
        background: #202331;
      }
    }
  }

}