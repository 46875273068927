.round-detail{
    background: #1B1F2A;
    border-radius: 12px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 25px;
    &-header{
        color: #E8E13F;
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 0px 25px;
        margin-bottom: 19px;
    }
    &-data{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 24px;
        .data-item{
            width: 100%;
            height: 47px;
            padding: 0px 24px;
            min-height: 47px;
            display: flex;
            align-items: center;
            list-style: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            &-name{
                width: 210px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 0.8rem;
                svg{
                    margin-right: 12px;
                }
            }
            &-value{
                width: calc(100% - 210px);
                padding-right: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 0.8rem;
                font-weight: bold;
            }
        }
    }
    .name{
        flex: 1;
        display: flex;
        align-items: center;
    }
    .cashout{
        width: 150px;
    }
    .earnings{
        width: 120px;
        display: flex;
        align-items: center;
        svg{
            min-width: 24px;
            height: 24px;
            margin-right: 4px;
        }
    }
    &-players-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 12px;
        padding-right: 28px;
        margin-bottom: 12px;
        color: #FFFFFF;
        font-size: 0.6rem;
        line-height: 0.8rem;
        width: 100%;
    }
    &-players{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 400px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 12px;
        padding-bottom: 15px;
        .player {
            display: flex;
            align-items: center;
            padding-left: 7px;
            padding-right: 16px;
            height: 50px;
            min-height: 50px;
            width: 100%;
            background: #2A2E3D;
            border-radius: 12px;
            margin-bottom: 6px;
            a{
                display: flex;
                align-items: center;
                margin-right: 15px;
            }
            .name{
                display: flex;
                align-items: center;
                a{
                    border: 2px solid rgba(255, 255, 255, 0.2);
                    border-radius: 11px;
                    width: 40px;
                    height: 40px;
                    padding: 2px;
                    img{
                        width: 100%;
                        border-radius: 7px;
                        &.streamer{
                            filter: blur(5px);
                        }
                    }
                    &.grey{
                        border-color: #A3A5B7;
                    }
                    &.blue{
                        border-color: #93C7F4;
                    }
                    &.yellow{
                        border-color: #E8E13F;
                    }
                    &.orange{
                        border-color: #E8903F;
                    }
                    &.violet{
                        border-color: #BC93FE;
                    }
                    &.red{
                        border-color: #E8533F;
                    }
                    &.fire{
                        border-color: #FD6228;
                    }
                }
                .l{
                    height: 23px;
                    border-radius: 8px;
                    padding: 0px 9px;
                    color: #000000;
                    font-size: 0.6rem;
                    background-color: rgba(#fff, .1);
                    min-width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    line-height: 0.8rem;
                    margin: auto;
                    margin-left: 8px;
                    &.grey{
                        background: #A3A5B7;
                    }
                    &.blue{
                        background: #93C7F4;
                    }
                    &.yellow{
                        background: #E8E13F;
                    }
                    &.orange{
                        background: #E8903F;
                    }
                    &.violet{
                        background: #BC93FE;
                    }
                    &.red{
                        background: #E8533F;
                    }
                    &.fire{
                        background: linear-gradient(82.31deg, #FD6228 67.56%, #FFBA2D 111.37%);
                    }
                }
            }
            span {
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 0.7rem;
            }
            .cashout{
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
            .earnings{
                color: #FFFFFF;
                ont-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
            &.won{
                .earnings{
                    color: #46BE60;
                }
            }
            &.lose{
                .earnings{
                    color: #F05F31;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .round-detail{
        width: 100%;
        padding-top: 14px;
        &-header{
            font-size: 0.8rem;
            line-height: 1rem;
            padding: 0px 14px;
            margin-bottom: 12px;
        }
        .close-popup{
            top: 10px;
            right: 10px;
        }
        &-data{
            margin-bottom: 12px;
            .data-item{
                padding: 0px 12px;
                &-name{
                    width: 120px;
                    font-size: 0.6rem;
                    svg{
                        margin-right: 8px;
                    }
                }
                &-value{
                    width: calc(100% - 120px);
                    font-size: 0.6rem;
                }
            }
        }
        .cashout{
            width: 100px;
            display: none;
        }
        .earnings{
            width: 50px;
        }
        &-players-header{
            padding: 0px 12px;
            padding-right: 24px;
        }
        &-players{
            max-height: calc(calc(var(--vh, 1vh) * 100) - 440px);
            padding: 0px 12px;
            padding-bottom: 4px;
            .player {
                padding-left: 7px;
                padding-right: 7px;
                a{
                    margin-right: 6px;
                }
                .name{
                    a{
                        min-width: 40px;
                    }
                    .l{
                        height: 18px;
                        font-size: 0.5rem;
                        line-height: 0.6rem;
                        margin-left: 4px;
                    }
                    span:not(.l){
                        display: block;
                        max-width: calc(100vw - 220px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                span {
                    font-size: 0.6rem;
                    line-height: 0.7rem;
                }
                .cashout{
                    font-size: 0.6rem;
                }
                .earnings{
                    font-size: 0.6rem;
                }
            }
        }
    }
}