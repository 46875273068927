.mines {
  &__grid-wrapper {
    position: relative;
    box-shadow: 0px 0px 50px rgba(16, 16, 16, 0.25);
    z-index: 1;
    .lock {
      background: rgba(27, 31, 42, 0.9) !important;
      border-radius: 15px;
      backdrop-filter: blur(5px) !important;
      z-index: 0;
      &__button {
        border-radius: 12px !important;
        padding: 15px 32px !important;
      }
    }
  }

  &__grid {
    padding: 40px 31px;
    position: relative;
    display: inline-grid;
    gap: 5px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    border-radius: 12px;
    background: rgba(27, 31, 42, 0.6);
    backdrop-filter: blur(3.29408px);

    @media (max-width: 1128px) and (min-width:1025px) {
      padding: 10px;
    }

    @media (max-width: 450px) {
      padding: 25px 15px;
    }

    &.twenty-five {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.sixteen {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.disabled {
      opacity: 0.7;
      cursor: default;
    }

    & .none {
      display: none;
    }

    & .with-bot {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(3px);
      border-radius: 12px;    

      &__button {
        padding: 9px 90px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: #49D167;
        box-shadow: 0px 0px 20px #141720;
        border-radius: 8px;

        & span {
          white-space: nowrap;
          font-weight: 800;
          font-size: 10px;
          color: #141720;
        }
      }
    }
  }
}