.ticket{
    width: 100%;
    display: flex;
    flex-direction: column;
    &-header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 34px;
        h2{
            color: #E8E13F;
            font-size: 1.2rem;
            line-height: 1.65rem;
            margin-bottom: 11px;
            display: flex;
            align-items: center;
            svg{
                margin-right: 12px;
                cursor: pointer;
            }
        }
        &-data{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            .operator{
                display: flex;
                align-items: center;
                &-image{
                    border: 2px solid rgba(255, 255, 255, 0.2);
                    border-radius: 11px;
                    width: 40px;
                    height: 40px;
                    padding: 2px;
                    margin-right: 16px;
                    img{
                        width: 100%;
                        border-radius: 7px;
                        &.streamer{
                            filter: blur(5px);
                        }
                    }
                }
                &-info{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .name{
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        font-weight: bold;
                        margin-bottom: 4px;
                    }
                    span{
                        color: #FFFFFF; 
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                    }
                }
            }
            button{
                background: #46BE60;
                border-radius: 12px;
                height: 40px;
                padding: 0px 32px;
                color: #141720;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: 900;
            }
        }
    }
    &-body{
        background: #1B1F2A;
        border-radius: 12px;
        &-messages{
            list-style: none;
            display: flex;
            flex-direction: column;
            height: calc(calc(var(--vh, 1vh) * 100) - 330px);
            min-height: calc(calc(var(--vh, 1vh) * 100) - 330px);
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 25px;
            width: 100%;
            &::-webkit-scrollbar{
                display: none;
            }
            .message{
                padding: 0px;
                width: 100%;
                background-color: transparent;
                margin-bottom: 24px;
                display: table;
                &-content{
                    display: flex;
                    align-items: center;
                    margin-bottom: 9px;
                    &-image{
                        border: 2px solid rgba(255, 255, 255, 0.2);
                        border-radius: 11px;
                        width: 40px;
                        height: 40px;
                        padding: 2px;
                        margin-right: 11px;
                        img{
                            width: 100%;
                            border-radius: 7px;
                            &.streamer{
                                filter: blur(5px);
                            }
                        }
                    }
                    &-name{
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        font-weight: bold;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        span{
                            color: rgba(255, 255, 255, 0.2);
                        }
                    }
                }
                &-text{
                    padding: 12px 16px;
                    background: #E8E13F;
                    border-radius: 9px;
                    color: #141720;
                    font-size: 0.7rem;
                    line-height: 1rem;
                    display: table;
                    margin-right: auto;
                }
                &.is-me{
                    // align-items: flex-end;
                    .message-content{
                        flex-direction: row-reverse;
                        &-image{
                            margin-right: 0px;
                            margin-left: 11px;
                            img{
                                width: 100%;
                                border-radius: 7px;
                                &.streamer{
                                    filter: blur(5px);
                                }
                            }
                        }
                        &-name{
                            align-items: flex-end;
                        }
                    }
                    .message-text{
                        background: #141720;
                        color: #FFFFFF;
                        margin-left: auto;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    &-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;margin-top: 28px;
        &-avatar{
            border: 3px solid rgba(255, 255, 255, 0.2);
            border-radius: 11px;
            width: 50px;
            height: 50px;
            padding: 2px;
            margin-right: 11px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 7px;
                &.streamer{
                    filter: blur(5px);
                }
            }
            // img{
            //     width: 52px;
            //     height: 52px;
            //     min-width: 52px;
            //     border-radius: 50%;
            //     margin-right: 14px;
            // }
        }
        &-input{
            height: 52px;
            width: 100%;
            background: #1B1F2A;
            border-radius: 12px;
            display: flex;
            align-items: center;
            padding-right: 25px;
            input{
                height: 100%;
                width: 100%;
                padding-left: 25px;
                padding-right: 25px;
                color: rgba(255, 255, 255, 0.6);
            }
            button{
                display: flex;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .ticket{
        &-header{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 24px;
            h2{
                font-size: 0.9rem;
                line-height: 1.25rem;
                margin-bottom: 16px;
            }
        }
        &-body{
            &-messages{
                list-style: none;
                display: flex;
                flex-direction: column;
                height: calc(calc(var(--vh, 1vh) * 100) - 330px);
                padding: 14px;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        &-form{
            &-avatar{
                width: 42px;
                height: 42px;
            }
            &-input{
                height: 42px;
            }
        }
    }
}