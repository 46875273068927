.mines {

  &__item {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #1B1F2A;
    border-radius: 12px;

    @media (max-width: 1280px) and (min-width: 1024px), (max-width:670px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 20px;
      grid-column-gap: 0px;
      grid-row-gap: 16px;
    }

    &.waiting {
      background-color: #262B38;
      @media (max-width: 1280px) and (min-width: 1024px), (max-width:670px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 20px 1ft;
        grid-column-gap: 0px;
        grid-row-gap: 16px;
      }
    }

    &.first-win {
      background: linear-gradient(90deg, rgba(70, 190, 96, 0.3) 0%, rgba(27, 31, 42, 1) 35%, rgba(27, 31, 42, 1) 65%, rgba(240, 95, 49, 0.3) 100%);
    }

    &.second-win {
      background: linear-gradient(90deg, rgba(240, 95, 49, 0.3) 0%, rgba(27, 31, 42, 1) 35%, rgba(27, 31, 42, 1) 65%, rgba(70, 190, 96, 0.3) 100%);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}