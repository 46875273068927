@import '../../fontSize';

.rakeback-money {
  background: #141720;
  border-radius: 8px;
}

.receive {
  font-family: 'Hauora';
  color: #FFFFFF;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 30px;
  background: url("../../../assets/rakeback/safe-4.png") no-repeat right 60% / 430px, url("../../../assets/bonuses/Light.svg") no-repeat right bottom / 40%, #1B1F2A;
  border-radius: 12px;

  &__title {
    font-size: $titleSize;
    font-weight: 600;
    margin-bottom: 23px;
    max-width: 180px;
    z-index: 1;
  }

  &__control {
    display: flex;
    z-index: 1;
  }

  &__text {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    padding: 8px 61px 8px 16px;
    background: url("../../../assets/rakeback/rect.png");
    background-size: cover;

    &>p {
      font-size: 10px;
    }
  }

  &__rect {
    position: absolute;
    top: 3px;
    left: 0px;
  }

  &__bg {
    max-height: 151px;
    width: 100%;
    position: absolute;
    top: 44px;
    left: -66px;
    bottom: 0px;
    right: 0px;
    z-index: 0;
  }

  &__money {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 24px;
    margin-left: 20px;
  }

  &__number {
    font-weight: 800;
    font-size: $numberSize;
    margin-right: 20px;
    margin-left: 10px;
  }

  &__button {
    color: #141720;
    font-size: $textSize;
    font-weight: 700;
    padding: 8px 15px;
    text-align: center;
    border-radius: 8px;
    background: #46BE60;
  }
}

@media (max-width: 1400px) and (min-width: 1024px),
(max-width: 767.9px) {
  .receive {
    background: url("../../../assets/rakeback/safe-2.png") no-repeat right -65px top -15px / contain,
      url("../../../assets/bonuses/Light.svg") no-repeat right bottom / contain,
      #1B1F2A;
  }
}

@media (max-width: 1400px) {
  .receive {
    justify-content: flex-start;

    &__title {
      max-width: 180px;
    }

    &__control {
      position: relative;
    }

    &__button {
      position: absolute;
      bottom: -50px;
      left: 0px;
      width: 38%;
    }
  }
}

@media (max-width: 767.9px) {
  .receive {
    &__button {
      width: 100%;
    }
  }
}

@media (max-width: 426px) {
  .receive {
    background: url("../../../assets/rakeback/safe-2.png") no-repeat right -35px top -7px/57%,
    url("../../../assets/bonuses/Light.svg") no-repeat right -30px bottom -45px/100%,
    #1B1F2A;

    &__bg {
      top: 83px;
    }

    &__money {
      justify-content: flex-start;
      flex: 1 1 auto;
    }
  }
}