.modal {
  position: absolute;
  transform: scale(0);
  color: aliceblue;
  filter: drop-shadow(0px 0px 30px #0F1218);
  border-radius: 11px;
  overflow: visible;
  z-index: 20000;
  max-width: 320px;

  &__arrow {
    position: absolute;
    top: 45px;
    right: 1px;
    height: 40px;
    width: 40px;
    transform: rotate(45deg);
    background-color: #1B1F2A;
    z-index: -1;
  }

  &.active {
    transform: scale(1);

  }

  &__content {
    padding: 10px 16px 22px 16px;
    border-radius: 12px;
    background: #1B1F2A;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 11px;
  }

  &__header {
    display: flex;

  }

  &__avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    padding: 2px;
    border: 2px solid;
    border-radius: 10.7866px;
  }

  &__img {
    width: 100%;
    border-radius: 7.84478px;
    margin: auto 0;
  }

  &__nickname {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
  }

  &__achievement {
    margin-top: 18px;
    margin-bottom: 14px;
    width: 243px;

  }

  &__achievement-line {
    display: flex;
    justify-content: space-between;

  }

  &__achievement-item {
    position: relative;
    border-radius: 8px;
    height: 28px;
    width: 28px;

    img {
      position: absolute;
      top: 5px;
      left: 3px;
      height: 21px;
      width: 21px;
    }

  }

  &_color-block {
    width: 110px;
    height: 18px;
    border-radius: 8px;
  }

  .grey {
    .modal_color-block {
      border: 2px solid #A3A5B7;
    }

    .modal__avatar {
      border-color: #A3A5B7;
    }

    .modal__lvl {
      background-color: #A3A5B7;
    }

    .line-wrapper {
      background: rgba(#A3A5B7, .1);
      // border: 1px solid #A3A5B7;
      .line {
        background-color: #A3A5B7;
      }
    }

    .modal__achievement-item {
      position: relative;
      background: #1B1F2A;
      border-radius: 8px;

      &::before {
        content: '';
        position: absolute;
        height: 16px;
        width: 12px;
        background-image: url('../../../assets/modal/locked.svg');
        top: 6px;
        left: 8px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(3px);
        border-radius: 8px;
        height: 28px;
        width: 28px;
        z-index: 1;
      }
    }
  }

  .blue {
    .modal_color-block {
      border: 2px solid #93C7F4;
    }

    .modal__avatar {
      border-color: #93C7F4;
    }

    .modal__lvl {
      background-color: #93C7F4;
    }

    .line-wrapper {
      background-color: rgba(#93C7F4, 0.1);
      // outline: 1px solid #93C7F4;
      .line {
        background-color: #93C7F4;
      }
    }

    .modal__achievement-item {
      background: #4762F1;
      border: none;
      border-radius: 8px;
    }
  }

  .yellow {
    .modal_color-block {
      border: 2px solid #E8E13F;
    }

    .modal__avatar {
      border-color: #E8E13F;
    }

    .modal__lvl {
      background-color: #E8E13F;
    }

    .line-wrapper {
      background: rgba(#E8E13F, .1);
      // outline: 1px solid #E8E13F;

      .line {
        background-color: #E8E13F;
      }
    }
  }

  .orange {
    .modal_color-block {
      border: 2px solid #E8903F;
    }

    .modal__avatar {
      border-color: #E8903F;
    }

    .modal__lvl {
      background-color: #E8903F;
    }

    .line-wrapper {
      background: rgba(#E8903F, .1);
      // outline: 1px solid #E8903F;
      .line {
        background-color: #E8903F;
      }
    }
  }

  .violet {
    .modal_color-block {
      border: 2px solid #BC93FE;
    }

    .modal__avatar {
      border-color: #BC93FE;
    }

    .modal__lvl {
      background-color: #BC93FE;
    }

    .line-wrapper {
      background: rgba(#BC93FE, .1);
      // outline: 1px solid #BC93FE;
      .line {
        background-color: #BC93FE;
      }
    }
  }

  .red {
    .modal_color-block {
      border: 2px solid #E8533F;
    }

    .modal__avatar {
      border-color: #E8533F;
    }

    .modal__lvl {
      background-color: #E8533F;
    }

    .line-wrapper {
      background: rgba(#E8533F, .1);
      // outline: 1px solid #E8533F;
      .line {
        background-color: #E8533F;
      }
    }
  }

  .fire {
    .modal_color-block {
      border: 2px solid #FD6228;
    }

    .modal__avatar {
      border-color: #FD6228;
    }

    .modal__lvl {
      background-color: #FD6228;
    }

    .line-wrapper {
      background: rgba(#FD6228, .1);
      // outline: 1px solid #FD6228;
      .line {
        background-color: #FD6228;
      }
    }
  }


  &__exp-block {
    display: flex;
    margin-top: 4px;
  }

  &__lvl {
    padding: 2px 8px 2px 8px;
    margin-right: 5px;
    box-sizing: border-box;
    color: #141720;
    border-radius: 8px;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__exp {
    display: flex;
    align-items: center;
  }

  &__stats-title {
    margin-bottom: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);

  }

  &__stats {
    background: #1B1F2A;
    border: 1px solid #2A2E3D;
    border-radius: 8px;
    padding: 15px;
  }

  &__stats-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0px;
  }

  &__stats-item-text {
    font-size: 10px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.4);
  }

  &__stats-item-value {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 14px;
    gap: 3px;
    color: #FFFFFF;

  }

  svg {
    max-width: 16px;
    height: auto;
  }

  & .admin-menu {
    margin-top: 22px;

    &__rights {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    &__icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      background-color: #494C55;

      & span {
        text-align: center;
      }

      &.ban {
        background-color: #F05F31;
      }

      &.mute {
        margin: 2px;
        align-self: center;
        width: 36px;
        height: 36px;
        flex: 0 0 36px;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3px;
      cursor: pointer;

      & span {
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.4);
        white-space: nowrap;
      }

      &.ban {
        & span {
          color: #F05F31;
        }
      }

      &-mute {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 3px;

        & span {
          padding-left: 5px;
        }
      }

      &.hours {
        padding: 0 0 0 12px;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 130px;
        border: 1px solid #2A2E3D;
        border-radius: 8px;

        & input {
          width: 30px;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }

    &__check {
      flex: 1 1 auto;
      min-height: 14px;
      min-width: 14px;
    }

    &__reward {
      padding: 13px;
      width: 100%;
      text-align: center;
      background-color: #4965F8;
      border-radius: 8px;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      color: #FFFFFF;
    }
  }

}

.line-wrapper {
  width: 109px;
  height: 18px;
  top: -2px;
  left: -1px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .line-xp-value {
    width: 100%;
    color: #6B6B78;
    font-size: 0.4rem;
    font-weight: 900;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
  }

  .line {
    box-sizing: content-box;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 110%;
  }
}

.line-xp {
  color: rgba(#fff, .7);
  font-weight: 900;
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin-left: 5px;
}

.line-xp-text {
  margin: 0 auto;
  margin-left: 5px;
  height: 14px;
  font-weight: 500;
  font-size: 10px;
  color: #FFFFFF;
  opacity: 0.7;
}