@import "../../fontSize";

.requirements {

  padding: 20px 24px 24px 24px;

  @media (min-width: 1920px) {
    grid-area: 1 / 1 / 2 / 2;
  }

  a {
    color: #4B68FF;
    word-wrap: inherit;
    border-bottom: 2px solid #4B68FF;
    cursor: pointer;
  }

  .item__point {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    margin: 8px 0;
  }

  span {
    margin-right: 8px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: #EAEAEA;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
  }

  form {
    display: inline;
    margin-bottom: 5px;
  }

  &__success {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;

    white-space: pre-wrap;
    text-align: center;

    font-size: 14px;
    font-weight: 600;
    line-height: 155%;
    color: #EAEAEA;

    & img {
      margin-bottom: 16px;
    }
    & svg {
      width: 40px;
      height: 40px;
      margin-bottom: 16px;
    }
  }

  &__value {
    color: #E8B93F;
    margin-right: 3px;
  }

  &__wrapper {
    //margin: 0 auto;
  }

  &__title {
    margin-bottom: 11px;
    font-weight: 700;
    font-size: $titleSize;
    line-height: 150%;
    color: #FFFFFF;

    @media (min-width: 2200px) {
      & {
        font-size: 16px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 16px 0;
    font-weight: 600;
    font-size: $textSize;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
    white-space: pre;

    &.true,
    &.false {
      position: relative;
      width: 100%;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        min-height: 14px;
        min-width: 14px;
      }
    }

    &.true {
      color: #49D167;

      &:after {
        background: url("../../../assets/check.svg") center no-repeat;
      }
    }

    &.false {
      color: #F05F31;

      &:after {
        background: url("../../../assets/inactive.svg") center no-repeat;
      }
    }

    &-with-coin {
      display: flex;
      align-items: center;

      svg {
        margin: 0 2px 0 4px;
      }
    }

    &:nth-child(3) {
      display: flex;
      align-items: center;
    }

    &__item {
      margin: 6px 0 0 17px;
      color: #FFFFFF;

      &:nth-child(3) {
        margin-bottom: 12px;
      }
    }


  }

  &__condition {
    display: flex;
    align-items: center;


  }

  &__line {
    width: 100%;
    padding: 16px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    align-items: flex-end;
    //
    //@media (max-width: 2200px) and (min-width: 1398px) {
    //  max-width: 330px;
    //}
    //
    //@media (min-width: 2200px) {
    //  & {
    //    max-width: 370px;
    //  }
    //}
  }


  &__text {
    color: #FFC700 !important;
    left: -75px !important;

    @media (min-width: 1919px) {
      left: -70px !important;
    }
  }

  &__list {
    margin: 2px 0 6px 20px;

    @media (max-width: 1070px) and (min-width: 1024px) {
      & {
        margin: 2px 0 6px 0;
      }

      @media (max-width: 480px) {
        & {
          margin: 2px 0 5px 0;
        }
      }
    }
  }

  .line {
    &__wrapper {
      position: relative;
      height: 15px;
      width: 107px;
      margin-left: 18px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      border-radius: 50px;
      overflow: hidden;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;

      @media (max-width: 1070px) and (min-width: 1024px) {
        & {
          margin-left: 9px;
        }
      }

      @media (max-width: 480px) {
        & {
          width: 80px;
          font-size: 9px;
        }
      }
    }

    &__value {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: #4B68FF;
      border-radius: 50px;
      z-index: 0;
    }

    &__text {
      position: absolute;
      font-weight: 400;
      width: 100%;
      top: 2px;
      left: 0;
      z-index: 100000;
    }

  }

  &__button {
    height: 33px;
    width: 100%;
    border: 1px solid #4B68FF;
    border-radius: 8px;
    color: #FFFFFF;
  }

  &__info {
    margin-top: 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: #7D869E;
    @media (max-width: 1919px) {
      display: none;
    }
  }

}