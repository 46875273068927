@mixin pseudo($width, $top, $left, $rotate) {
  content: '';
  display: block;
  width: $width;
  height: 2px;
  position: absolute;
  top: $top;
  left: $left;
  transform-origin: left center;
  transform: translateX(-0.707 * $width / 2) translateY(-50%) rotateZ($rotate) translateX(-1px);
  background-color: #141720;
  border-radius: 10px;
}

.arrow {
  display: inline-block;
  &__bg {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &__top {
    @include pseudo(8px, 50%, 50%, -45deg);
  }
  &__bottom {
    @include pseudo(8px, 50%, 50%, 45deg);
  }
}