.support {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-header {
        color: #E8E13F;
        font-size: 1.2rem;
        line-height: 1.65rem;
        font-weight: bold;
        margin-bottom: 24px;
    }

    &-body {
        display: flex;
        align-items: flex-start;

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;

            .support-item {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 47px;
                min-height: 47px;
                overflow: hidden;
                cursor: pointer;
                transition-duration: 1s;
                background: #1B1F2A;
                border-radius: 12px;
                margin-bottom: 16px;
                padding-left: 20px;
                padding-right: 12px;

                &-header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 47px;
                    min-height: 47px;
                    border-bottom: 1px solid transparent;

                    span {
                        color: #FFFFFF;
                        font-size: 0.7rem;
                        line-height: 0.8rem;
                    }

                    svg {
                        transition-duration: .2s;
                    }

                    .plus {
                        background: #E8E13F;
                        border-radius: 7.2px;
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &-body {
                    color: #FFFFFF;
                    padding-top: 15px;
                    padding-bottom: 30px;
                    font-size: 0.7rem;
                    line-height: 0.9rem;
                    transition-duration: .4s;
                    opacity: 0;
                    visibility: hidden;
                }

                &.active {
                    max-height: 500px;
                    min-height: unset;
                    overflow: visible;

                    .support-item-body {
                        opacity: 1;
                        visibility: visible;
                    }

                    .support-item-header {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                        svg {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

        &-nav,
        &-nav-mobile {
            min-width: 216px;
            width: 216px;
            padding: 16px 17px 22px 17px;
            background: #1B1F2A;
            border-radius: 12px;
            margin-left: 30px;

            &-mobile {
                display: none;
            }

            a {
                color: #E8E13F;
                font-size: 0.7rem;
                line-height: 1rem;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
                font-weight: normal;

                svg {
                    margin-right: 11px;

                    &:last-child {
                        path {
                            stroke: #E8E13F;
                        }
                    }
                }
            }

            &-list {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-bottom: 8px;

                &-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 16px;

                    a {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 0px;

                        span {
                            color: rgba(255, 255, 255, 0.6);
                            font-size: 0.6rem;
                            line-height: 0.8rem;
                        }

                        .count {
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 24px;
                            padding: 0px 13px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #FFFFFF;
                            font-size: 0.7rem;
                            line-height: 1rem;
                            font-weight: bold;

                            &-active {
                                background: rgba(232, 225, 63, 0.1);
                                color: #E8E13F;
                            }
                        }
                    }
                }
            }

            .create-ticket {
                width: 100%;
                height: 41px;
                color: #141720;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
        }
    }
}

.found-bug {
    position: relative;
    padding: 26px 10px 28px 188px;
    background: #1B1F2A;
    border-radius: 12px;
    margin-bottom: 30px;
    overflow: hidden;
    justify-content: end;

    &-arrows-decor,
    &-arrows-decor-mobile {
        position: absolute;
        top: -153px;
        left: -84px;
        bottom: -9px;
        height: 350px;

        &-mobile {
            display: none;
        }
    }

    &-attention-decor {
        position: absolute;
        left: 55px;
        bottom: 10px;
    }

    &__wrapper {
        justify-content: flex-end;
    }

    &__header {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
    }

    &__text {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.6);

        span {
            color: #FFFFFF;
        }
    }

    &__details {
        position: absolute;
        top: 16px;
        right: 58px;
        font-size: 12px;
        color: #E8E13F;

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 42px;
            height: 9px;
            width: 14px;
            background-image: url(../../src/assets/arrow.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

@media screen and (max-width: 1400px) {
    .support {
        &-header {
            font-size: 0.9rem;
            line-height: 1.2rem;
            margin-bottom: 14px;
        }

        &-body {
            flex-direction: column-reverse;

            &-content {
                .support-item {
                    min-height: 47px;
                    max-height: unset;
                    margin-bottom: 12px;

                    &-header {
                        min-height: 47px;
                        height: auto;

                        span {
                            padding-top: 15px;
                            padding-bottom: 15px;
                        }

                        .plus {
                            margin-left: 10px;
                        }
                    }

                    &-body {
                        max-height: 0px;
                        padding-top: 0px;
                        padding-bottom: 0px;
                    }

                    &.active {
                        max-height: 500px;
                        min-height: unset;
                        overflow: visible;

                        .support-item-body {
                            max-height: unset;
                            opacity: 1;
                            visibility: visible;
                            padding-top: 15px;
                            padding-bottom: 20px;
                        }
                    }
                }

                & .found-bug {
                    margin-bottom: 16px;
                }
            }

            &-nav {
                display: none;


                &-mobile {
                    display: block;
                    width: 100%;
                    padding: 16px 14px 22px 14px;
                    margin-left: 0;
                    margin-bottom: 12px;
                    background: #1B1F2A;
                    border-radius: 12px;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .support-body-content .found-bug {
        padding-bottom: 63px;
        padding-left: 130px;
        background: url("../assets/rays-bg.svg") no-repeat, #1B1F2A;
        overflow: visible;

        &-arrows-decor {
            display: none;
            &-mobile {
                display: block;
                position: absolute;
                top: 10px;
                left: 10px;
                height: 150px;
            }
        }

        &__details {
            top: auto;
            right: auto;
            bottom: 20px;
        }
    }
}