.game-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    gap: 8px;
    position: relative;


    .game-control {
        display: flex;
        width: 100%;
        margin-bottom: 11px;

    }

    .game-start-buttons {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        & > span {
            font-weight: 600;
            font-size: 0.7rem;
            color: rgba(255, 255, 255, 0.6);
        }
    }
    .rates {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-header {
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.6rem;
            line-height: 0.8rem;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &-body {
            display: flex;
            align-items: center;

            &-list {
                margin: 5px;
                display: flex;
                align-items: center;

                li {
                    padding: 3.5px 16.5px;
                    border: 1px solid transparent;
                    background-color: #2A2E3D;
                    border-radius: 6px;
                    color: #FFFFFF;
                    font-size: 0.7rem;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition-duration: .2s;

                    &:not(:last-child) {
                        margin-right: 5px;
                    }

                    &:hover {
                        background: rgba(75, 104, 255, 0.1);
                        border: 1px solid #4B68FF;
                    }
                }
            }

            &-clear {
                margin-right: 5px;
                padding: 5px 9px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                color: #F05F31;
                font-size: 0.6rem;
                line-height: 0.8rem;
                text-transform: uppercase;
                font-weight: bold;
                cursor: pointer;
                transition-duration: .2s;

                &:hover {
                    background: rgba(240, 95, 49, 0.2);
                    border-radius: 6px;
                }
            }
        }
    }

    .amount {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        margin-right: 0px;


        &-header {
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.6rem;
            line-height: 0.8rem;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &-value {
            padding: 0px 0px 0px 16px;
            display: flex;
            align-items: center;
            width: 100%;
            background: #1B1F2A;
            border: 1px solid #2A2E3D;
            border-radius: 8px;

            input:focus::-webkit-input-placeholder {
                color: transparent;
            }

            input:focus:-moz-placeholder {
                color: transparent;
            }

            /* FF 4-18 */
            input:focus::-moz-placeholder {
                color: transparent;
            }

            /* FF 19+ */
            input:focus:-ms-input-placeholder {
                color: transparent;
            }

            /* IE 10+ */

            input::-webkit-input-placeholder {
                font-weight: 400;
                font-size: 14px;
                color: #494C55;
            }

            input::-moz-placeholder {
                font-weight: 400;
                font-size: 14px;
                color: #494C55;
            }

            /* FF 4-18 */
            input::-moz-placeholder {
                font-weight: 400;
                font-size: 14px;
                color: #494C55;
            }

            /* FF 19+ */
            input::-ms-input-placeholder {
                font-weight: 400;
                font-size: 14px;
                color: #494C55;
            }

            /* IE 10+ */
            svg {
                min-width: 18px;
            }

            input {
                width: 100%;
                padding-left: 16px;
                height: 100%;
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }

            &-clear {
                border: 1px solid transparent;
                border-radius: 20px;
                padding: 0px 23px;
                height: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #F05F31;
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                cursor: pointer;
                transition-duration: .2s;

                &:hover {
                    border: 1px solid rgba(240, 95, 49, 0.2);
                }
            }
        }

        &-values {
            display: flex;
            align-items: center;
            align-self: flex-start;

            span {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                text-transform: uppercase;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                span {
                    color: #FFFFFF;
                    font-size: 0.7rem;
                    line-height: 1rem;
                    font-weight: bold;
                    margin-left: 7px;
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .game-amount {
        flex-direction: column;

        & .game-control {
            flex-direction: column;
        }

        .rates {
            width: 100%;

            &-body {
                width: 100%;

                &-list {
                    li {
                        flex: 1;
                    }
                }

                &-clear {
                    margin-right: 5px;
                }
            }
        }

        .amount {
            width: 100%;
            align-items: flex-start;
            margin-bottom: 20px;

            &-value {
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1250px) and (min-width: 1024px),
(max-width: 767.9px) {
    .game-amount {

        .rates {
            &-body {
                &-list {
                    & li {
                        padding: 3.5px 8px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1120px) and (min-width: 1024px),
(max-width: 525px) {
    .game-amount {
        .amount {
            &-value {
                margin-top: 40px;
                position: relative;
                height: 35px;
            }
        }

        .rates {
            position: absolute;
            width: 100%;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);

            &-body-list {
                margin: 0;
                width: 100%;

                & li {
                    flex: 1 1 auto;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .game-amount {
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 12px;
        background: #1B1F2A;
        padding: 14px 14px 0px;
        border-radius: 8px;

        &.radius {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .rates {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            order: 1;

            &-header {
                font-size: 0.6rem;
                line-height: 0.7rem;
                margin-bottom: 12px;
            }
        }

        .amount {
            width: 100%;
            align-items: flex-start;
            margin-bottom: 14px;

            &-header {
                font-size: 0.5rem;
                line-height: 0.7rem;
                margin-bottom: 8px;
            }

            &-value {
                width: 100%;

                & input {
                    padding-left: 8px;
                }
            }

            &-values {
                display: none;
            }
        }
    }
}

@media (max-width: 435px) {
    .game-amount .rates-body-clear {
        position: absolute;
        top: -20px;
        right: -7px;
    }

    .game-amount .rates-body-list li {
        padding: 3px 8px;
        margin-top: 7px;
    }
}