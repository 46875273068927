
.banner-wrapper{
    width: 100%;
    height: 48px;
    min-height: 40px;
    background: #F05F31;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    max-height: 40px;
    transition-duration: .2s;
    &.hidden{
        opacity: 0;
        visibility: hidden;
        max-height: 0px;
        min-height: 0px;
    }
}
.banner{
    // width: 1100px;
    width: 100%;
    padding-right: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-message{
        color: #FFFFFF;
        font-size: 0.7rem;
        line-height: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 25px;
    }
    svg{
        cursor: pointer;
    }
}