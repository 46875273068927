.depend__point {
  &:nth-child(1) .depend__num{
    color: #A3A5B7;
  }
  &:nth-child(2) .depend__num{
    color: #93C7F4;
  }
  &:nth-child(3) .depend__num{
    color: #6ce86f;
  }

  &:nth-child(4) .depend__num{
    color: #E8E13F;
  }

  &:nth-child(5) .depend__num{
    color: #E8903F;
  }

  &:nth-child(6) .depend__num{
    color: #BC93FE;
  }

  &:nth-child(7) .depend__num{
    color: #E8533F;
  }

}

