.mines {

    &__header {
        display: flex;
        position: relative;
        align-items: flex-end;
        margin-top: 17px;

        @media (max-width: 440px) {
            margin-bottom: 20px;
        }

        @media (max-width: 1140px) and (min-width: 1024px),
        (max-width:670px) {
            display: block;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__back {
        padding-top: 20px;
        margin-right: 12px;
    }

    &__next {
        margin: auto 0;
    }

    &__body {
        display: grid;
        grid-template-columns: 1fr 342px;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 13px;
        
        @media (min-width: 1919px) {
            display: grid;
            grid-template-columns: 1fr 314px 446px;
            // grid-template-columns: 446px 1fr 446px;
            grid-template-rows: repeat(2, auto);
            grid-row-gap: 15px;

        }

        @media (max-width: 1390px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
            grid-column-gap: 0;
            grid-row-gap: 16px;
        }

        @media (max-width: 1140px) and (min-width: 1024px),
        (max-width:670px) {
            margin-top: 20px;
        }
    }

}

@media (max-width: 1250px) {
    .mines .game-header {
        padding-top: 20px;
        height: 58px;
    }
}