.bonuses__card-giveaway {
  & .card__win {
    right: 14px;
    top: 14px;
    position: absolute;
    border-radius: 100%;
    padding: 27px 20px;
    transform: translate(50%, -50%) rotateZ(45deg);;
    font-family: 'Hauora';
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: #FFFFFF;
    background: #4B68FF;

    &-text {
      transform: translate(0px, 10px);
      display: block;
    }
  }

  & .card__subtitle {
    font-size: 1.2rem !important;
    color: #E8E13F;
    display: flex;
    align-items: center;
  }

  & .card__quality {
    font-size: 16px;
    margin-right: 15px;
  }

  & .giveaway-icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  & .giveaway-money {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
  }

  & .giveaway-button {
    display: inline-block;
    color: #141720;
    font-size: 12px;
    font-weight: 700;
    margin-top: 25px;
    padding: 10px 50px;
    border-radius: 8px;
    background-color: #E8E13F;
  }
}

@media (max-width: 1024px) {
  .bonuses__card-giveaway {
    background-size: contain !important;
  }
}