.chat-rules-popup{
    width: 350px;
    padding: 12px 16px 20px 16px;
    background: #1B1F2A;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
    .close-popup{
        top: 12px;
        right: 12px;
    }
    &-header{
        font-size: 0.8rem;
        line-height: 1.1rem;
        font-weight: bold;
        color: #E8E13F;
        margin-bottom: 13px;
    }
    &-body{
        color: #FFFFFF;
        font-size: 0.9rem;
        line-height: 1.2rem;
        width: 100%;
        // height: 510px;
        overflow-y: auto;
        padding-right: 10px;
        .hello{
            color: rgba(255, 255, 255, 0.4);
            font-size: 0.6rem;
            line-height: 0.8rem;
            margin-bottom: 13px;
            font-weight: normal;
        }
        span{
            display: flex;
            align-items: center;
            svg{
                margin-right: 14px;
                min-width: 20px;
            }
            color: #FFFFFF;
            font-size: 0.6rem;
            line-height: 0.8rem;
            font-weight: bold;
        }
        &::-webkit-scrollbar {
            background: transparent;
            width: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            background-color: #fff;
            border-radius: 0px;
        }
    }
}