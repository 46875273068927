@import '../../fontSize';
@import "../../Bonuses/levelColor";

.depend {
  color: #FFF;
  padding: 20px 24px;
  border-radius: 12px;
  overflow: hidden;
  background:#1B1F2A;

  &__title {
    font-weight: 700;
    font-size: $titleSize;
  }

  &__list {
    height: 0;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    margin-top: 6px !important;


    &.active {
      margin-top: 17px;
      padding-bottom: 25px;
      height: 100%;
    }
  }

  &__point {
    // display: grid;
    // grid-template-columns: 70px 3fr 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 0 12px;
    height: 31px;
    margin-bottom: 6px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    

    & .depend__num {
      font-family: 'Hauora', sans-serif;
      font-weight: 700;
      font-size: $textSize;
      width: 63px;
    }

    & .depend__percent {
      display: flex;
      margin-left: auto;
      text-align: center;
      font-size: $textSize;
      font-weight: 500;

      svg {
        margin-right: 4px;
      }

      &-active {
        color: #46BE60;
        text-shadow: 0 0 10px rgba(70, 190, 96, 0.7);
      }
    }

    & .depend__value {
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__line {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.05;
    }
  }

}