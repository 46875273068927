.bonus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #E8B93F;
  border-radius: 8px;
  background-color: #1B1F2A;

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  &__text {
    font-weight: 700;
    font-size: 12px;
    color: #E8B93F;
  }
}