.probability {
  position: relative;
  grid-area: 1 / 2 / 2 / 3;
  align-self: start;
  background: #1B1F2A;
  border-radius: 12px;
  padding: 25px;
  overflow: hidden;
  min-height: 256px;

  @media (min-width: 1919px) {
    grid-area: 2 / 3 / 3 / 4;
  }

  @media (max-width: 1390px) {
    grid-area: 3 / 1 / 4 / 2;
    margin-top: 0;
  }

  @media (max-width:440px) {
    padding: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;

    @media (max-width:440px) {
      font-size: 14px;
    }
  }

  &__image {
    position: absolute;
    top: -17px;
    left: 164px;
  }

  &__mountain {
    background-image: url('../../../assets/mountain.svg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 12px;
    left: 0px;
  }

  &__list {
    height: min-content;
    display: flex;
    flex-direction: column;
    margin-top: 22px;
  }

  &__point {
    align-items: center;
    display: flex;
    padding: 2.8px 12px;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    margin-bottom: 4px;
    justify-content: space-between;
    color: #FFFFFF;
    @media (min-width: 1919px) {
      padding: 8px 16px;
    }

    &:nth-child(1) .probability__percent {
      color: #5B7FFF;
    }

    &:nth-child(2) .probability__percent {
      color: #8960FF;
    }

    &:nth-child(3) .probability__percent {
      color: #C956FF;
    }

    &:nth-child(4) .probability__percent {
      color: #FF4975;
    }

    &:nth-child(5) .probability__percent {
      color: #FF4949;
    }

    &:nth-child(6) .probability__percent {
      background: linear-gradient(131.63deg, #FFE37F 10.29%, #FFF3C7 39.09%, #FFE37F 61.15%, #FFF3C7 96.69%, #FFE37F 127.94%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-shadow: 0px 0px 10px rgba(255, 228, 131, 0.5);
    }

    &:last-child {
      margin: 0;
    }
  }

  &__num {
    display: flex;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
  }

  &__coin {
    margin-right: 3px;
    display: flex;
  }

  &__line {
    width: 100%;
    height: 1px !important;
    max-height: 1px;
    background: rgba(255, 255, 255, 0.05);
    display: none;
  }

  &__percent {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}