.racer{
    display: flex;
    align-items: center;
    z-index: 1;

    .lose {
        opacity: 0.5;
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;
        flex-grow: 1;
        width: 0px;
        overflow: hidden;
    }

    a {
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-radius: 11px;
        width: 40px;
        height: 40px;
        padding: 2px;
        margin-bottom: 4px;
        margin-right: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 100%;
            border-radius: 7px;
            &.streamer{
                filter: blur(5px);
            }

        }

        &.grey{
            border-color: #A3A5B7;
        }
        &.blue{
            border-color: #93C7F4;
        }
        &.yellow{
            border-color: #E8E13F;
        }
        &.orange{
            border-color: #E8903F;
        }
        &.violet{
            border-color: #BC93FE;
        }
        &.red{
            border-color: #E8533F;
        }
        &.fire{
            border-color: #FD6228;
        }
    }

    &-car {
        height: 16px;
        width: 16px;
        min-width: 16px;
        border-radius: 5px;

        &.racer-red {
            background-color: #E8533F;
        }

        &.racer-blue {
            background-color: #4B68FF;
        }
    }

    &-level {
        height: 18px;
        border-radius: 30px;
        padding: 0px 9px;
        color: #000000;
        font-size: 0.5rem;
        background-color: rgba(#fff, .1);
        min-width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 0.8rem;
        margin-bottom: 4px;
        &.grey{
            background: #A3A5B7;
        }
        &.blue{
            background: #93C7F4;
        }
        &.yellow{
            background: #E8E13F;
        }
        &.orange{
            background: #E8903F;
        }
        &.violet{
            background: #BC93FE;
        }
        &.red{
            background: #E8533F;
        }
        &.fire{
            background: linear-gradient(82.31deg, #FD6228 67.56%, #FFBA2D 111.37%);
        }
    }

    &-name {
        display: flex;
        align-items: center;
        gap: 4px;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        font-size: 12px;
        text-align: center;

        & span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
        }
    }

    &-bet {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            color: #FFFFFF;
            font-size: 0.7rem;
            line-height: 1rem;
            font-weight: bold;
            margin-left: 2px;
        }

        &.win span {
            color: #49D167;
        }

        &.lose span {
            color: #E8533F;
        }
    }
}
