.create-button {
  position: relative;
  padding: 15px 56px 15px 32px;
  border-radius: 12px;
  cursor: pointer;
  background-color: #46BE60;
  box-shadow: 0 0 20px rgba(70, 190, 96, 0.2);

  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #141720;

  &:disabled {
    filter: brightness(.5);
  }

  & img {
    position: absolute;
    top: 17px;
    right: 32px;

    @media (max-width: 1255px) {
      top: 11px;
      right: 21px;
    }

    @media (max-width: 670px) {
      display: none;
    }

  }

  @media (max-width: 1255px) {
    padding: 10px 44px 10px 23px;
    font-size: 10px;
  }
  @media (max-width: 670px) {
    padding: 10px 30px;
    
  }

  @media (max-width: 1140px) and (min-width: 1024px),
  (max-width:670px) {
    width: 100%;
  }
}