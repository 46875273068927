@media (min-width: 1430px) {
  .streetrace-players {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;

    .streetrace-player {
      &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        max-width: calc(25% - 10px);
        position: relative;
        background-color: #1B1F2A;
        border-radius: 12px;
      }

      &-amount {
        display: none;
      }

      &-list {
        display: none;
      }

      &-others {
        display: none;
      }
    }
  }
}

@media (max-width: 1430px) {
  .streetrace-players {
    .streetrace-player {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      position: relative;
      background-color: #1B1F2A;
      padding: 10px;
      border-radius: 12px;
      margin-bottom: 5px;

      &-list {
        display: flex;
        flex-direction: column;

        .bets-avatar {
          padding: 1px;
          width: 30px;
          height: 30px;
          min-width: 30px;
          margin-right: 6px;
          border-width: 1px;
          border-radius: 8.25px;
          background-color: #1B1F2A;

          .level {
            width: 14px;
            height: 14px;
            font-weight: 700;
            font-size: 8px;
          }
        }

        .bet-item {
          width: auto;
          min-height: 46px;
          position: relative;
          display: flex;
          align-items: center;
          border-radius: 12px;
          padding-right: 10px;
          background: #1B1F2A;
          pointer-events: none;

          &:not(:first-child) {
            margin-top: 10px;
          }

          &:not(:last-child) {
            padding-bottom: 5px;

            &:after {
              content: '';
              position: absolute;
              height: 1px;
              width: 100%;
              top: 110%;
              background-color: #2A2E3D;
            }
          }

          span {
            color: #FFFFFF;
            font-size: 0.6rem;
            line-height: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .bet-value {
            display: flex;
            align-items: center;
            margin-left: auto;
          }

          .bet {
            color: #FFFFFF;
            font-size: 0.7rem;
            line-height: 1rem;
            flex: 1;
            display: flex;
            justify-content: flex-end;
            margin-left: 6px;
            font-weight: 700;

            &.green-bet {
              color: #00CE78;
            }

            &.red-bet {
              color: #D7392F;
            }
          }

          svg {
            margin-left: 12px;
            margin-bottom: 2px;
          }

          &.is-me {
            background: lighten(#1B1F2A, 10%);
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 5px;

            &:after {
              content: '';
              display: none;
            }
          }
        }
      }
    }
  }
}



@media screen and (min-width: 2600px ) {
  .streetrace-player {
    width: 400px;
  }
}

@media (max-width: 1430px) {
  .streetrace-players {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    & .streetrace-player {
      min-width: 345px;
      height: 45px;
      flex-direction: row;
      padding: 0;

      &-amount {
        padding-left: 30px;
        font-weight: 700;
        font-size: 12px;
        color: #fff;
      }

      &-list {
        padding-left: 8px;
        height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        margin-right: 5px;

        .bet-item {
          max-width: 30px;
          background-color: transparent;

          &:not(:last-child) {
            padding-bottom: 0;
          }

          &:not(:first-child) {
            margin-top: 0;
          }

          &.is-me {
            background-color: transparent;
            margin-bottom: 0;
          }
        }
      }

      &-others {
        margin-left: auto;
        margin-right: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        & .player-others {
          position: relative;
          height: 23px;
          width: 33px;

          & .player-other {
            position: absolute;

            &:nth-child(2) {
              left: 5px;
            }

            &:nth-child(3) {
              left: 10px;
            }

            .bets-avatar {
              width: 23px;
              height: 23px;
              min-width: 23px;
              background-color: #1B1F2A;
              border-radius: 8px;
              padding: 0;
              pointer-events: none;

              .level {
                display: none;
              }
            }
          }
        }

        & .player-show {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.6);
        }

        .player-show-arrow {
          transition: all 0.3s ease;
        }

        &.active {
          .player-show-arrow {
            transform: rotate(180deg) translateY(2px);
          }
        }
      }


      &.red {
        background: url('../../../assets/people-red.svg') no-repeat left 12px center/15px 15px,
                    #1B1F2A;
      }

       &.yellow {
        background: url('../../../assets/people-yellow.svg') no-repeat left 12px center/15px 15px,
                    #1B1F2A;
      }

      &.green {
        background: url('../../../assets/people-green.svg') no-repeat left 12px center/15px 15px,
                    #1B1F2A;
      }

      &.blue {
        background: url('../../../assets/people-blue.svg') no-repeat left 12px center/15px 15px,
                    #1B1F2A;
      }
    }
  }
}

@media (max-width: 1340px) and (min-width: 1024px), (max-width: 767px){
  .streetrace-players {
    display: block;
  }
}
