.refferals {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 16px;

  &-header {
    width: 100%;
    height: 125px;
    background: #E8E13F;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 13px;
    z-index: 1;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-left: 18px;

      span {
        color: #141720;
        font-size: 1.2rem;
        line-height: 106%;
        font-weight: 800;
        width: 170px;
      }
    }

    .balance {
      min-width: 230px;
      width: 230px;
      height: 94px;
      background: #141720;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px;

      span {
        color: #E8E13F;
        font-size: 0.8rem;
        line-height: 1.1rem;
      }

      .fuel {
        display: flex;
        align-items: center;

        span {
          font-size: 1.75rem;
          line-height: 112%;
          margin-right: 6px;
          font-weight: bold;
        }

        svg, img {
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  &-level {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    background: #1B1F2A;
    border-radius: 12px;
    width: 100%;
    margin-top: -8px;
    padding: 14px 13px 20px 26px;

    &-header {
      color: #E8E13F;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 800;
      margin-bottom: 19px;
      display: flex;
      align-items: center;

      svg, img {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        margin-top: 4px;
      }
    }

    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .level-data {
      display: flex;
      align-items: center;

      .number-wrapper {
        border: 4.7px solid rgba(255, 255, 255, 0.2);
        width: 94px;
        height: 94px;
        border-radius: 25.85px;
        padding: 4px;
        margin-right: 18px;
        filter: drop-shadow(0px 4.94737px 55.6579px rgba(70, 190, 96, 0.56));

        .number {
          width: 100%;
          height: 100%;
          background: #46BE60;
          box-shadow: 0 4.94737px 55.6579px rgba(70, 190, 96, 0.56);
          border-radius: 18.8px;
          color: #fff;
          font-size: 2rem;
          line-height: 2.8rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-title {
          color: #FFFFFF;
          font-size: 0.7rem;
          line-height: 1rem;
        }

        &-fuel {
          display: flex;
          align-items: center;
          margin-bottom: 7px;

          span {
            color: #FFFFFF;
            font-size: 1.2rem;
            line-height: 1.15rem;
            font-weight: bold;
          }

          svg, img {
            margin-right: 4px;
          }
        }

        &-line {
          height: 2px;
          width: 215px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 38px;
          margin-bottom: 7px;

          span {
            display: flex;
            height: 100%;
            background: #46BE60;
            border-radius: 38px;
          }
        }

        &-count {
          color: #FFFFFF;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 0.7rem;
          line-height: 1rem;

          span {
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.6rem;
            line-height: 0.8rem;
            margin-left: 8px;
          }
        }
      }
    }

    .balance {
      margin-left: 10px;
      background: #141720;
      border-radius: 16px;
      width: 330px;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 26px;
      padding-right: 18px;

      &-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          color: #FFFFFF;
          font-size: 0.8rem;
          line-height: 1.1rem;
        }

        .fuel {
          display: flex;
          align-items: center;

          span {
            font-size: 1.2rem;
            line-height: 1.15rem;
            font-weight: bold;
            margin-right: 9px;
          }
        }
      }

      &-with {
        width: 105px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #141720;
        font-size: 0.5rem;
        line-height: 0.7rem;
        background: #46BE60;
        font-weight: bold;
        border-radius: 8px;
      }
    }
  }

  .refferals-info {
    display: flex;
    gap: 16px;
  }

  .title {
    color: #E8E13F;
    font-size: 1.2rem;
    line-height: 1.15rem;
    font-weight: bold;
    margin-bottom: 24px;
  }

  &-links {
    width: 100%;
    display: flex;
    padding: 30px 50px 57px 30px;
    background: #1B1F2A;
    border-radius: 12px;
    flex-direction: column;

    .input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;

      &:nth-child(2) .input-content input {
        text-transform: uppercase;
      }

      span {
        color: #7D869E;
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .input-content {
        background: #1B1F2A;
        border: 1px solid #2A2E3D;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 43px;

        input {
          width: 100%;
          height: 100%;
          padding-right: 12px;
          color: #FFFFFF;
          font-size: 0.7rem;
          line-height: 1rem;
          font-weight: bold;
        }

        svg, img {
          margin-right: 6px;
          min-width: 16px;
          cursor: pointer;
        }

        button {
          background: rgba(232, 225, 63, 0.4);
          border-radius: 8px;
          width: 93px;
          height: 35px;
          min-width: 93px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #E8E13F;
          font-size: 0.5rem;
          line-height: 0.7rem;
        }
      }
    }
  }

  .statistic {
    &-item {
      background: #141720;
      border-radius: 12px;
      padding: 16px 20px;

      span {
        color: #FFFFFF;
        font-size: 0.7rem;
        line-height: 1rem;
        margin-bottom: 5px;
      }

      & > span {
        color: #7D869E;
      }

      .fuel {
        margin-top: 8px;
        display: flex;
        align-items: center;

        span {
          font-size: 1.2rem;
          line-height: 112%;
          font-weight: 800;
          margin-bottom: 0;
        }

        svg, img {
          width: 26px;
          height: 26px;
          margin-right: 6px;
        }
      }
    }
  }

  .refferals-statistic {
    width: 100%;
    background: #1B1F2A;
    border-radius: 12px;
    padding: 30px 50px 30px 30px;

    .statistic-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  &-statistic {
    display: flex;
    flex-direction: column;

    .title {
      color: #E8E13F;
      font-size: 1.2rem;
      line-height: 1.15rem;
      font-weight: bold;
      margin-bottom: 23px;
    }
  }

  .statistics-levels-lists {
    display: flex;
    gap: 16px;
  }

  .statistic-levels {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #141720;
    border-radius: 16px;
    padding: 33px 40px 37px;

    &-header {
      display: flex;
      align-items: center;
      padding: 0 12px;
      margin-bottom: 17px;

      span {
        flex: 1;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;

        &:first-child {
          width: 68px;
          min-width: 68px;
          max-width: 68px;
        }

        &:last-child {
          width: 105px;
          min-width: 105px;
          max-width: 105px;
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;

      .level-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        justify-content: space-between;

        .level-wrapper {
          height: 33px;
          width: 33px;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0 2.21053px 24.8684px rgba(221, 255, 228, 0.23);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 4px;
          margin-right: 30px;

          .level-number {
            color: #fff;
            font-size: 0.9rem;
            line-height: 1.25rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.active {
            border: 2px solid rgba(255, 255, 255, 0.2);
            width: 42px;
            height: 42px;
            border-radius: 11px;
            padding: 2px;
            margin-right: 26px;
            margin-left: 0;
            filter: drop-shadow(0px 4.94737px 55.6579px rgba(70, 190, 96, 0.56));

            .level-number {
              width: 100%;
              height: 100%;
              background: #46BE60;
              box-shadow: 0 4.94737px 55.6579px rgba(70, 190, 96, 0.56);
              border-radius: 8px;
            }
          }
        }

        .bonus-value, .deposit-value {
          color: #FFFFFF;
          font-size: 0.8rem;
          line-height: 1.1rem;
          display: flex;
          align-items: center;
          padding-left: 15px;
          flex: 1;

          svg, img {
            margin-left: 4px;
          }
        }

        .bonus-value {
          padding-left: 10px;
        }

        .ref-bonus-value {
          background: rgba(232, 225, 63, 0.1);
          border-radius: 55px;
          height: 35px;
          padding: 0 16px;
          color: #E8E13F;
          font-size: 0.7rem;
          line-height: 1rem;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1559px) {
  .statistics-levels-lists {
    flex-direction: column;
  }

  .refferals-info {
    flex-direction: column;
  }
}

@media (max-width: 1400px) and (min-width: 1024px) {
  .refferals {
    &-level {
      padding-right: 26px;

      &-content {
        flex-direction: column;
        align-items: center;

        .level-data {
          width: 100%;
          margin-bottom: 20px;
        }

        .balance {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1150px) and (min-width: 1024.9px) {
  .refferals-links {
    flex-direction: column;

    .input-wrapper {
      width: 100%;

      &:first-child {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .refferals {
    padding-top: 0;
    padding-bottom: 20px;

    &-header {
      height: 110px;
      flex-direction: column;
      align-items: center;
      padding-right: 0;
      z-index: 1;

      img {
        display: none;
      }

      .info {
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-bottom: 10px;

        span {
          width: 100%;
          text-align: center;
        }
      }
    }

    &-level {
      width: 100%;
      background: #1B1F2A;
      border-radius: 12px;
      height: auto;
      flex-direction: column;
      width: 100%;
      margin-top: -20px;
      padding: 40px 10px 10px;
      margin-bottom: 20px;

      &-content {
        display: flex;
        flex-direction: column;
      }

      .level-data {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      .balance {
        margin: 0;
        width: 100%;
      }
    }

    &-links {
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 0;

      .input-wrapper {
        width: 100%;
        margin-bottom: 16px;

        &:first-child {
          margin-right: 0;
        }
      }
    }

    &-statistic {
      .title {
        color: #E8E13F;
        font-size: 0.9rem;
        line-height: 1.25rem;
        margin-bottom: 16px;
      }

      .statistic {
        flex-wrap: wrap;

        &-items {
          gap: 9px !important;
        }

        &-item {
          flex: unset;
          height: auto;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 20px;

          span {
            color: #FFFFFF;
            font-size: 0.7rem;
            line-height: 1rem;
            margin-bottom: 5px;
          }

          .fuel {
            display: flex;
            align-items: center;

            span {
              font-size: 1.2rem;
              line-height: 112%;
              font-weight: 800;
              margin-bottom: 0;
            }

            svg, img {
              width: 26px;
              height: 26px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .refferals  {
    .statistic-levels {
      background: transparent;
      padding: 0;
    }

    .refferals-statistic {
      padding: 20px;
    }

    .statistic-levels-list .level-item .level-wrapper.active {
      margin-right: 6px;
    }

    .statistic-levels-list .level-item .level-wrapper {
      margin-right: 10px;
    }

    .statistic-levels-header span:first-child {
      min-width: 33px;
      max-width: 48px;
    }

    .statistic-levels-list .level-item .ref-bonus-value {
      background: transparent;
      padding: 0;
    }

    .statistic-levels-header span:last-child {
      width: 105px;
      min-width: auto;
      margin-left: 16px;
    }

    .statistic-levels:last-child {
      .statistic-levels-header {
        display: none;
      }
    }
  }
}