.mines {

  &__card {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;

    @media (max-width:1458px) and (min-width:1210px), (max-width: 860px) and (min-width: 710px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width:1344px) and (min-width:1210px) {
      width: 30px;
      height: 30px;
    }

    &-active {
      & .mines__shirt {
        transform: rotateY(180deg) !important;
      }

      & .mines__fail,
      & .mines__success {
        position: relative;
        transform: rotateY(0deg) !important;

      }
    }
  }

  &__shirt {
    position: absolute;
    background: url("../../../assets/mines/mine-front.svg") no-repeat center center/cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotateY(0deg);
    backface-visibility: hidden;
    transition: 0.3s ease;

  }

  &__fail {
    position: absolute;
    background: url("../../../assets/bonuses/daily-face-fail.png") no-repeat center center/cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: 0.3s ease;
  }

  &__success {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../../assets/bonuses/daily-face-prize.png") no-repeat center center / contain;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: 0.3s ease;

    & span {
      display: flex;
      font-weight: 800;
      font-size: 12px;
      color: #DB950E;
      margin-top: auto;
      margin-bottom: 4px;
      width: 89%;
      height: 31%;
      background: #1B1F2A;
      border-radius: 30px;
      align-items: center;
      justify-content: center;

      @media (max-width: 1378px) and (min-width: 1025px), (max-width: 755px) {
        font-size: 10px;
        margin-bottom: 3px;
      }
      
      @media (max-width: 1258px) and (min-width: 1025px), (max-width: 629px) {
        font-size: 8px;
        font-weight: 700;
        margin-bottom: 2px;
      }
      @media (max-width: 1185px) and (min-width: 1025px), (max-width: 0px) {
        font-size: 6px;
      }
      
    }
  }
}