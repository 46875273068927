.bonuses__card-dailyBonus {
  position: relative;
  background:
    url('../../../../assets/bonuses/daily-bg.png') no-repeat right bottom / 405px,
    url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / cover,
    #1B1F2A;
}

@media (max-width: 1024px) {
  .bonuses__card-dailyBonus {
    background:
      url('../../../../assets/bonuses/daily-bg.png') no-repeat right bottom / 405px,
      url('../../../../assets/bonuses/Light.svg') no-repeat right bottom / 50%,
      #1B1F2A;
  }
}

@media (max-width: 735px) {
  .bonuses__card-dailyBonus {
    background:
      url('../../../../assets/bonuses/daily-bg.png') no-repeat right bottom / 300px,
      #1B1F2A;
  }
}