.roulette {
    display: flex;
    flex-direction: column;

    .game-header .information {
        display: none;
    }

    &-stat {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-top: 18px;

        @media (max-width: 1130px) {
            padding-top: 0;
        }

        &-history {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 1130px) {
                align-self: flex-end;
            }

            span {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                margin-bottom: 8px;
            }

            .history {
                display: flex;
                align-items: center;

                li {
                    border-radius: 5px;
                    height: 16px;
                    width: 16px;
                    cursor: pointer;
                    margin-right: 6px;

                    &.green {
                        background: #46BE60;
                    }

                    &.red {
                        background: #f57a07;
                    }

                    &.black {
                        background: #2A2E3D;
                    }
                }
            }
        }

        &-last {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 1024px) and (max-width: 1130px),
            (max-width: 475px) {
                flex-direction: column;
            }

            &-span {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                margin-left: auto;

                @media (min-width: 1024px) and (max-width: 1130px),
                (max-width: 475px) {
                    padding-bottom: 7px;
                }
            }

            .values {
                display: flex;
                align-items: center;

                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 16px;

                    span {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        font-weight: bold;
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        padding: auto;
                    }

                    .color {
                        border-radius: 5px;
                        height: 16px;
                        width: 16px;
                        margin-right: 6px;

                        &.green {
                            background: #46BE60;
                        }

                        &.red {
                            background: #f57a07;
                        }

                        &.black {
                            background: #2A2E3D;
                        }
                    }
                }
            }
        }
    }

    &-bets {
        margin-top: 45px;
        margin-bottom: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media screen and (min-width: 2200px) {
    .roulette {
        &-bets {
            margin: 45px auto 15px;
            width: 1410px;
        }
    }
}

@media (max-width: 1400px) {
    .roulette {
        &-bets {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .roulette {
        &-stat {
            padding-top: 12px;
            margin-bottom: 65px;

            &-history {
                span {
                    font-size: 0.5rem;
                    line-height: 0.7rem;
                }

                .history {
                    li {
                        height: 12px;
                        width: 12px;
                    }
                }
            }

            &-last {
                span {
                    font-size: 0.5rem;
                    line-height: 0.7rem;
                }

                .values {
                    &-item {
                        .color {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }

        &-bets {
            background: #1B1F2A;
            padding-bottom: 10px;
            border-radius: 8px;
            width: 100%;
            margin: 0px 0 20px;
        }
    }
}

.roulette .timer {
    top: 80px;

    @media (max-width: 1125px) {
        top: 85px;
    }

    @media (max-width: 1025px) {
        top: 65px;
    }

    @media (max-width: 475px) {
        top: 87px;
    }
}