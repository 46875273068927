.foundbug {
    position: relative;
    &__back {
        position: absolute;
        top: 4px;
        left: 0px;
        height: 24px;
        width: 24px;
    }

    &__body-content {
        width: 100%;
    }

    &__header {
        margin-left: 36px;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #E8E13F;
    }

    &__under-header {
        margin-top: 27px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #C0C6D8;
    }

    &__body {
        display: flex;
        @media (max-width: 1400px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__item  {
        margin-top: 35px;         
        
    }

    
    &__body-content-nav {
        @media (max-width: 1400px) {
            width: 100%;
            padding: 16px 0 0 0;
            margin-left: 0;
        }
    }
}

.item {
    &__header {
        display: flex;
        color: white;
        margin-bottom: 22px;
    }

    &__number {
        display: flex;
        min-width: 40px;
        height: 40px;
        background: #1B1F2A;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    }

    &__answer {
        max-width: 440px;
        display: flex;
        align-items: center;

        & img {
            margin-left: 3px;
        }

        @media (min-width: 1500px) {
            max-width: 550px;
        }
        @media (min-width: 2000px) {
            max-width: 700px;
        }
        @media (min-width: 2400px) {
            max-width: 70%;
        }
    }
    
    &__title {
        display: flex;
        margin-left: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 155%;
        color: #FFFFFF;
        align-items: center;
    }
    
    &__body {
        padding: 25px 24px 25px 25px;
        background: #1B1F2A;
        border-radius: 12px;
        font-size: 12px;
        line-height: 155%;
        color: #C0C6D8;
    }

    &__container {
        margin-top: 9.5px;
        margin-left: 35px;
    }

    &__point {
        position: relative;
        margin-top: 7.5px;
    }

    &__answ-two {
        margin-top: 16.5px;
    }

    .nope {
        display: none;
    }
}

.foundbug .item .item__point {
    position: relative;
    margin-top: 7.5px;
    &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -9px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #C0C6D8;
    }
    
}