.btn {
    padding: 20px;
    background-color: #E8E13F;
    text-align: center;
}

.nav {
    width: 260px;
    min-width: 260px;
    height: calc(calc(var(--vh, 1vh) * 100) - 117px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    &.banner-nav {
        height: calc(calc(var(--vh, 1vh) * 100) - 117px - 48px);
    }

    &-rights {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        margin-top: 13px;
        padding-left: 10px;
    }

    &-social {
        display: flex;
        align-items: center;
        padding-left: 10px;

        &-item {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            background: #2A2E3D;
            border-radius: 12px;
        }
    }

    &-links {
        justify-content: end;
        height: 100%;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        padding-top: 26px;

        &-item {
            color: rgba(255, 255, 255, 0.4);
            font-size: 0.7rem;
            line-height: 1rem;
            margin-bottom: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        &-foundbug {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 1;
            color: #ABB2C7;
            background: #2A2E3D;
            border-radius: 8px;
            padding: 10px 23px;
            margin-top: 9px;

            span {
                position: relative;
                margin-left: 8px;
            }
        }
    }

    &-menu {
        width: 100%;
        background: #1B1F2A;
        border-radius: 12px;
        padding: 20px 20px 10px 20px;

        .account-btn {
            width: 100%;
            transition-duration: .2s;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: #E8E13F;

            span {
                margin-left: 15px;
                color: #141720;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
        }

        &-btns-links {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            width: 100%;
            margin-top: 20px;

            &-cash {
                background: rgba(232, 225, 63, 0.1);
                width: 105px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                color: #E8E13F;
                font-size: 0.5rem;
                line-height: 0.7rem;
                margin-left: 11px;
                border: 1px solid #E8E13F;
            }

            &-deposit {
                width: 105px;
                height: 32px;
            }
        }

        &-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-top: 10px;

            &-item {
                width: 100%;
                height: 54px;
                display: flex;
                align-items: center;

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;

                    span {
                        margin-left: 16px;
                        color: rgba(255, 255, 255, 0.2);
                        font-size: 0.8rem;
                        line-height: 1.1rem;
                        transition-duration: .2s;
                    }

                    svg {
                        path {
                            color: rgba(255, 255, 255, 0.2);
                            transition-duration: .2s;
                        }
                    }
                }

                &.active {
                    a {
                        span {
                            color: #E8E13F;
                        }

                        svg {
                            path {
                                fill: #E8E13F;
                                fill-opacity: 1;
                            }
                        }
                    }
                }

                &:hover {
                    a {
                        span {
                            color: #E8E13F;
                        }

                        svg {
                            path {
                                fill: #E8E13F;
                                fill-opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .nav {
        width: 300px;
        min-width: 300px;

        &-menu {
            &-btns-links {
                &-cash {
                    width: 126px;
                    height: 42px;
                    font-size: 0.6rem;
                }

                &-deposit {
                    width: 126px;
                    height: 42px;
                    font-size: 0.6rem;
                }
            }

            &-list {
                &-item {
                    a {
                        span {
                            font-size: 0.9rem;
                            line-height: 1.1rem;
                        }
                    }
                }
            }
        }

        &-links {
            &-item {
                font-size: 0.8rem;
            }
        }

        &-rights {
            font-size: 0.7rem;
        }

        &-social {
            &-item {
                width: 40px;
                height: 40px;
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    .nav {
        width: 260px;
        min-width: 260px;
        height: calc(calc(var(--vh, 1vh) * 100) - 117px);
        display: flex;
        flex-direction: column;
        display: none;

        &-rights {
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.6rem;
            line-height: 0.8rem;
            margin-top: 13px;
            padding-left: 10px;
        }

        &-social {
            display: flex;
            align-items: center;
            padding-left: 10px;

            &-item {
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                background: #2A2E3D;
                border-radius: 12px;
            }
        }

        &-links {
            height: 100%;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            padding-top: 26px;

            &-item {
                color: rgba(255, 255, 255, 0.4);
                font-size: 0.7rem;
                line-height: 1rem;
                margin-bottom: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-menu {
            width: 100%;
            background: #1B1F2A;
            border-radius: 12px;
            padding: 20px 20px 34px 20px;

            &-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                margin-top: 30px;

                &-item {
                    width: 100%;
                    height: 54px;
                    display: flex;
                    align-items: center;

                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        width: 100%;

                        span {
                            margin-left: 16px;
                            color: rgba(255, 255, 255, 0.2);
                            font-size: 0.8rem;
                            line-height: 1.1rem;
                            transition-duration: .2s;
                        }

                        svg {
                            path {
                                transition-duration: .2s;
                            }
                        }
                    }

                    &.active {
                        a {
                            span {
                                color: #E8E13F;
                            }

                            svg {
                                path {
                                    fill: #E8E13F;
                                    fill-opacity: 1;
                                }
                            }
                        }
                    }

                    &:hover {
                        a {
                            span {
                                color: #E8E13F;
                            }

                            svg {
                                path {
                                    fill: #E8E13F;
                                    fill-opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
