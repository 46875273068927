.streetrace-track{
    width: 100%;
    height: 390px;
    background-color: rgba(#fff, .05);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    margin-top: 12px;

    .test {
        position: absolute;
        width: var(--th);
        height: 10px;
    }

    .track-wrapper{
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        .track{
            width: 105%;
            position: absolute;
            bottom: calc(-6vw + 50px );
            transition: .3s transform linear;
        }
    }
    .cars{
        position: absolute;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        justify-content: center;
        bottom: calc(-1vw - 200px);        
        svg{
            width: calc(9vw - 80px);
            margin-right: 1.7vw;
            transform: translateX(0.5vw);
            height: 100%;
            @media (min-width: 1920px) {
                margin-right: 6vw;

            }
            @media (min-width: 1024px) {
                width: 7%;
            }
            &:nth-child(1){
                @media (min-width: 1024px) {
                    margin-right: 1.9vw;
                }
                @media (min-width: 1920px) {
                    margin-right: 2.1vw;
                }
            }
            &:nth-child(3){
                width: calc(9.8vw - 80px);
                margin-right: 0.9vw;
                @media (min-width: 1024px) {
                    width: 8%;
                    margin-right: 1vw;
                }
            }
        }        
    }
}

@media screen and (min-width: 2500px) {
    .streetrace-track{
        height: 500px;
        .track-wrapper{
            width: 100%;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
        }
        .cars{
            bottom: calc(-1vw - 240px);
        }
    }
    
}

@media screen and (min-width: 1920px) {
    .streetrace-track{
        .cars{
            svg{
                width: calc(8vw - 80px);
                margin-right: 1.7vw;
                transform: translateX(0.5vw);
                &:nth-child(3){
                    width: calc(9vw - 80px);
                    margin-right: 1.2vw;
                    margin-left: -0.5vw;
                }
            }
            
        }
    }
}

@media screen and (min-width: 2048px) {
    .streetrace-track{
        height: 500px;
        .cars{
            bottom: calc(-5vw - 200px);
            svg{
                &:nth-child(3){
                    width: calc(9vw - 80px);
                    margin-right: 1.2vw;
                    margin-left: -0.5vw;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .streetrace-track{
        height: 170px;
        .track-wrapper{
            width: 100%;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
            .track{
                bottom: -10%; // 266
            }
        }
        .cars{
            bottom: calc(-1vw - 90px);
            svg{
                width: calc(10vw - 10px);
                &:nth-child(3){
                    width: calc(12vw - 10px);
                    // margin-right: 1.6vw;
                }
            }
        }
    }
}
.wide {
    @media (min-width: 1920px) {
        margin: 0 34px !important;
    }
}
