.crash-chart{
    padding-bottom: 36.7%;
    width: calc(370px + (1030 - 370) * ((100% - 370px) / (1033 - 370)));
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    background: #1B1F2A;
    border-radius: 12px;
    overflow: hidden;

    &-background {
        background: url("../../../assets/crash/bg-city.png") no-repeat center bottom -50px / cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }
    &-title{
        position: absolute;
        top: 30px;
        left: 35px;
        display: flex;
        align-items: flex-end;
        height: 63px;
        span:not(.value){
            font-family: 'Futura';
            font-size: 2rem;
            font-weight: 900;
            margin-right: 20px;
            background: linear-gradient(90.55deg, #63DAFF 9.16%, #58FF9B 87.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #0B2349;
            display: block;
            margin-bottom: 3px;
        }
        .value{
            font-family: 'Futura';
            font-size: 2.7rem;
            font-weight: 900;
            background: linear-gradient(90.55deg, #63DAFF 9.16%, #58FF9B 87.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #0B2349;
            display: block;
        }
    }
    &-line{
        height: 14px;
        transform-origin: left;
        display: flex;
        align-items: flex-start;
        position: unset;
        span:not(.anchor){
            height: 100%;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: static;
            transition-duration: .4s;
            background: linear-gradient(255.98deg, #4E5161 20.53%, #1F2431 93.26%);
            &.grey{
                background: linear-gradient(255.98deg, #4E5161 20.53%, #1F2431 93.26%);
            }
            &.green{
                background: linear-gradient(255.98deg, #46BE60 20.53%, #68FF80 67.08%, #1F2431 93.26%);
            }
            &.blue{
                background: linear-gradient(255.98deg, #4B68FF 20.53%, #3EAEFF 52.3%, #1F2431 93.26%);
            }
            &.gold{
                background: linear-gradient(255.98deg, #E8E13F 20.53%, #E8E13F 37.91%, #FFAD32 61.01%, #1F2431 93.26%);
            }
        }
        .anchor{
            height: 100%;
            width: 1px;
        }
    }
    &-bets{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        span{
            color: #FFC700;
            font-size: 0.7rem;
            line-height: 0.9rem;
            position: absolute;
            transition-duration: .2s;
            animation: bet 3s linear forwards;
        }
    }
}

@media (max-width: 1920px) {
    .crash-chart {
        width: calc(370px + (1030 - 370) * ((100% - 370px) / (1053 - 370)));
        padding-bottom: 36%;
    }
}

@media (max-width: 1350px) and (min-width: 1024.9px), (max-width: 767px) {
    .crash-chart {
        min-height: 250px;
        &-background {
            & video {
                opacity: 0;
            }
        }
    }
}

@media (max-width: 1250px){
    .crash-chart {
        padding-bottom: 36.7%;
    }
}

@media screen and (max-width: 1024px) {
    .crash-chart{
        &-title{
            top: 10px;
            left: 25px;
            height: 50px;
            span:not(.value){
                font-size: 1.4rem;
                margin-right: 14px;
                margin-bottom: 2px;
            }
            .value{
                font-size: 2rem;
            }
        }
        &-line{
            height: 8px;
        }
    }
}

@media (max-width: 867px) {
    .crash-chart {

        &-background {

        }
    }
}

// @keyframes fire1 { 0% {transform: translate(136.726248px,417.857895px) translate(-131px,-417.857895px)} 23.333333% {transform: translate(136.726248px,417.857895px) translate(-143px,-417.857895px)} 46.666667% {transform: translate(136.726248px,417.857895px) translate(-134px,-417.857895px)} 73.333333% {transform: translate(136.726248px,417.857895px) translate(-144px,-417.857895px)} 100% {transform: translate(136.726248px,417.857895px) translate(-131px,-417.857895px)}} 
// @keyframes fire2 { 0% {transform: translate(119.348857px,417.857895px) skewX(0deg) skewY(1deg) scale(0.840039,1) translate(-131px,-417.857895px)} 23.333333% {transform: translate(119.348857px,417.857895px) skewX(0deg) skewY(1deg) scale(0.840039,1) translate(-143px,-417.857895px)} 46.666667% {transform: translate(119.348857px,417.857895px) skewX(0deg) skewY(1deg) scale(0.840039,1) translate(-134px,-417.857895px)} 73.333333% {transform: translate(119.348857px,417.857895px) skewX(0deg) skewY(1deg) scale(0.840039,1) translate(-144px,-417.857895px)} 100% {transform: translate(119.348857px,417.857895px) skewX(0deg) skewY(1deg) scale(0.840039,1) translate(-131px,-417.857895px)}} 
// @keyframes wheel1 { 0% {transform: translate(487.285126px,424.774185px) rotate(0deg)} 100% {transform: translate(487.285126px,424.774185px) rotate(360deg)}} 
// @keyframes wheel2 { 0% {transform: translate(191.575264px,424.774155px) rotate(0deg)} 100% {transform: translate(191.575264px,424.774155px) rotate(360deg)}}
@keyframes bet {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}