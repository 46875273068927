.lock {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    max-width: 60px;
    max-height: 60px;
  }

  &__text {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px;
    text-shadow: 0px 0px 10px #000000;
  }

  &__button {
    display: flex;
    align-items: center;
    color: #141720;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    padding: 5px 25px;
    border-radius: 8px;
    background-color: #49D167;

    color: #1B1F2A;

    svg {
      margin-right: 8px;
    }

    &[disabled] {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}