.bets-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 11px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  padding: 2px;

  img {
    width: 100%;
    border-radius: 7px;

    &.streamer {
      filter: blur(5px);
    }
  }

  &.grey {
    border-color: #A3A5B7;
  }

  &.blue {
    border-color: #93C7F4;
  }

  &.yellow {
    border-color: #E8E13F;
  }

  &.orange {
    border-color: #E8903F;
  }

  &.violet {
    border-color: #BC93FE;
  }

  &.red {
    border-color: #E8533F;
  }

  &.fire {
    border-color: #FD6228;
  }

  .level {
    position: absolute;
    bottom: -7px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #000000;
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 0.8rem;
    background-color: rgba(#fff, .1);

    &.grey {
      background: #A3A5B7;
    }

    &.blue {
      background: #93C7F4;
    }

    &.yellow {
      background: #E8E13F;
    }

    &.orange {
      background: #E8903F;
    }

    &.violet {
      background: #BC93FE;
    }

    &.red {
      background: #E8533F;
    }

    &.fire {
      background: linear-gradient(82.31deg, #FD6228 67.56%, #FFBA2D 111.37%);
    }
  }
}