.mines {
  
  &__details {
    // margin-top: 30px;
    padding: 24px;
    grid-area: 1 / 1 / 2 / 2;
    align-self: start;
    background: #1B1F2A;
    border-radius: 12px;
    // height: 242px;

    @media (min-width: 1919px) {
      max-height: 242px;
    }
    
    @media (max-width:440px) {
      padding: 16px;
    }
    
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    
    @media (max-width:440px) {
      font-size: 14px;
    }
  }
  
  &__inputs {
    margin-top: 25px;
    margin-bottom: 2px;
    
    @media (max-width:440px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  &__input {
    display: flex;
    margin-top: 17px;
    justify-content: space-between;
    color: #FFFFFF;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    
    input:focus:-moz-placeholder {
      color: transparent;
    }
    
    /* FF 4-18 */
    input:focus::-moz-placeholder {
      color: transparent;
    }
    
    /* FF 19+ */
    input:focus:-ms-input-placeholder {
      color: transparent;
    }
    
    /* IE 10+ */
    
    input::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #494C55;
    }
    
    input::-moz-placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #494C55;
    }
    
    /* FF 4-18 */
    input::-moz-placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #494C55;
    }
    
    /* FF 19+ */
    input::-ms-input-placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #494C55;
    }
    
    
    @media (max-width: 440px) {
      display: contents;
    }
    
    &-title {
      margin-right: 5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #ABB2C7;
      
      @media (max-width: 1032px) {
        margin-right: 0;
      }
      
      @media (max-width: 440px) {
        margin: 16px 0 10px 0;
      }
    }
    
  }
  
  &__coin {
    width: 235px;
    display: flex;
    padding: 8px 16px;
    background: #1B1F2A;
    border: 1px solid #2A2E3D;
    border-radius: 8px;
    
    &-input {
      width: 100%;
      margin-left: 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      
    }
    
    &-input {
      font-size: 12px;
    }
    
    @media (max-width:440px) {
      width: 100%;
    }
    
    
  }
  
  .mines__button {
    width: 73px;
    height: 25px;
    max-height: 25px;
    background: #1B1F2A;
    outline: 1px solid #2A2E3D;
    border-radius: 8px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #ABB2C7;
    -webkit-transition: all 0.2s 0s ease;
    -moz-transition: all 0.2s 0s ease;
    -o-transition: all 0.2s 0s ease;
    transition: all 0.2s 0s ease;
    &:hover {
      background: rgba(42, 46, 61, 0.4);
      color: #EAEAEA;
    }
    
    @media (max-width:440px) {
      width: 100px;
    }
    &.active {
        background: #E8E13F;
        border-radius: 8px;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        color: #2A2E3D;
        &:hover {
          background: #E8E13F;
          color: #2A2E3D;
        
        }
        & svg path {
            fill-opacity: 1;
            fill: #1B1F2A;
        }
        & .information__svg {
          opacity: 1;
        }
      }
  }
  
  &__type {
    display: flex;
    width: 235px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 440px) {
      margin: 0;
      width: 100%;
    }

  }

  &__rounds {
    width: 235px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 440px) {
      margin: 0;
      width: 100%;
    }

    .mines__button {
      height: 25px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:nth-child(4) {
        width: 91px;
      }
      
      @media (max-width:440px) {
        width: 59px;
      }
      
    }

  }
  
  &__popup {
    margin-left: 5px;
  }

}

@media (max-width: 1024px) {
  .mines__details {
    & .information__popup {
      transform: translateX(-100px) !important;
    }
  }
}