.mines {
  &__header {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;

    @media (max-width: 1140px) and (min-width: 1024px),
    (max-width:670px) {
      margin-top: 12px;
    }
  }

  &__private {
    margin-right: 16px;
    margin-left: auto;
    padding: 14px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background-color: rgba(77, 219, 108, 0.1);
    border: 1px solid #49D167;
    border-radius: 12px;

    @media (max-width: 1255px) {
      padding: 10px 25px;

    }

    @media (max-width: 1140px) and (min-width: 1024px),
    (max-width:670px) {
      width: 100%;
    }

    & span {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #49D167;
      @media (max-width: 1255px) {
        font-size: 10px;  
      }
    }
  }

  &__creategame {

    @media (max-width: 1140px) and (min-width: 1024px),
    (max-width:670px) {
      width: 100%;
    }
  }

  & .createbutton {
    margin: 0;
  }
}