.daily-bonus-result {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(27, 31, 42, 0.7);
  backdrop-filter: blur(3px);
  z-index: 3;

  &__wrapper {
    max-width: 300px;
    @media (max-width: 550px) {
      transform: scale(0.7);
    }
  }

  &__card {
    padding: 30px 40px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('../../../assets/game-over-daily.png') no-repeat center center, #272C39;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #EAEAEA;

  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    text-align: center;
    color: #7D869E;
    margin: 8px 0 16px;
  }

  &__bet {
    margin: 0 auto;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    //max-width: 124px;
    background-color: #1B1F2A;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;

    & svg {
      margin-right: 5px;
      width: 23px;
      height: 23px;
    }
  }

}