.withdraw-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 12px 16px;
    background: #141720;
    margin-bottom: 12px;
    border-radius: 12px;
    &-header{
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 11px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 10px;
        a{
            border: 2px solid rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            width: 24px;
            min-width: 24px;
            height: 24px;
            padding: 2px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                border-radius: 4px;
                &.streamer{
                    filter: blur(5px);
                }
            }
        }
        .username{
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.6rem;
            line-height: 0.8rem;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &-body{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 11px;
        padding: 0px;
        img{
            width: 90px;
            height: 90px;
            margin-right: 14px;
            object-fit: contain;
            margin-left: 10px;
            margin-right: 10px;
        }
        .info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 5px;
            .name{
                color: #FFFFFF;
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
            }
            .price{
                display: flex;
                align-items: center;
                span{
                    color: #FFFFFF;
                    font-size: 0.8rem;
                    line-height: 1rem;
                    font-weight: bold;
                    margin-left: 4px;
                }
                .discount{
                    color: #D7392F;
                    font-size: 0.5rem;
                    line-height: 1.1rem;
                    font-weight: bold;
                }
            }
        }
    }
    &-accept{
        width: 100%;
        height: 38px;
        font-size: 0.6rem;
        font-weight: bold;
        margin-bottom: 13px;
    }
    &-status{
        display: flex;
        width: 100%;
        height: 2px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 38px;
        margin-bottom: 8px;
        overflow: hidden;
        .status-line{
            height: 100%;
            width: 0%;
            background: #46BE60;
            position: relative;
            transition-duration: .2s;
        }
    }
    &-description{
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        // .time{
        //     color: #FFB800;
        // }
    }
}

@media screen and (max-width: 1024px) {
    .withdraw-item{
        background: #2A2E3D;
        a{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-accept{
            width: 100%;
        }
    }
}