.rakeback {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    color: #E8E13F;
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 337px;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
  }
}

@media (max-width: 1520px) and (min-width: 1400px){
  .rakeback {

    &__statistic {
      grid-column-start: auto !important;
    }

    &__depend {
      grid-column-start: auto !important;
    }
  }
}

@media (max-width: 1400px) {
  .rakeback {
    &__header {
      margin-bottom: 10px;
    }

    &__wrapper {
      grid-template-rows: 0.8fr auto 1fr;
      transition: all .3s ease;
    }
    &__receive {
      padding: 25px;
      min-height: 211px;
      order: 1;
    }
    &__statistic {
      grid-column-start: 1;
      grid-column-end: 3;
      order: 3;
    }
    &__depend {
      grid-column-start: 1;
      grid-column-end: 3;
      order: 2;
    }
  }
}
