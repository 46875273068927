.privacy{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 0.7rem;
    line-height: 155%;
    font-weight: 600;
    padding-top: 20px;
    margin-bottom: 5.5px;
    color: #C0C6D8;
    h1{
        margin-top: 30px;
        margin-bottom: 25px;
        font-size: 1.05rem;
        font-weight: 600;
        line-height: 1.5rem;
    }
    h2{
        margin-top: 10px;
        margin-bottom: 16px;
        font-size: 0.9rem;
        line-height: 155%;
        font-weight: 600;
        padding-left: 30px;
    }
    h3{
        margin-top: 10px;
        margin-bottom: 16px;
        padding-left: 30px;
        font-size: 0.7rem;
        line-height: 155%;
    }
    ul{
        list-style: disc;
        padding-left: 70px;
        margin-top: 8px;
        margin-bottom: 8px;
        word-break: break-word;
        li{
            margin-bottom: 10px;
            &.margin{
                margin-bottom: 40px;
            }
        }
    }
    p{
        font-weight: normal;
        line-height: 155%;
        strong{
            font-weight: bold;
        }
        &.text{
            padding-left: 30px;
            line-height: 155%;
        }
        &.margin{
            margin-bottom: 10px;
        }
    }
    &-header{
        color: #E8E13F;
        font-size: 1.2rem;
        line-height: 1.15rem !important;
        font-weight: 600;
        margin-top: 0px !important;
        margin-bottom: 5.5px !important;
    }
    &-updated{
        color: rgba(#C0C6D8, .5);
        margin-bottom: 20px;
        font-size: 0.7rem;
        line-height: 155%;
    }
    a{
        color: #00f0ff;
        text-decoration: underline;
    }
}

@media screen and(max-width: 1024px) {
    .privacy{
        &-header{
            font-size: 0.9rem;
            line-height: 1.2rem;
            margin-bottom: 8px !important;
        }
    }
}