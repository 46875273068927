.streetrace-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    max-width: calc(25% - 10px);
    position: relative;
    background-color: #1B1F2A;
    padding: 10px;
    border-radius: 12px;

    &-x {
        color: #FFFFFF;
        font-size: 0.7rem;
        line-height: 1rem;
        margin-bottom: 10px;
        align-self: flex-start;
        font-weight: 700;
    }

    &-bankroll {
        display: flex;
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        align-items: center;

        span {
            margin-left: 6px;
            color: #FFFFFF;
            font-size: 0.7rem;
            line-height: 1rem;
            font-weight: 700;
        }

        &.green {
            span{
                color: #00CE78;
            }
        }
        &.red {
            span{
                color: #D7392F;
            }
        }
    }

    &-btn {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        color: #141720;
        font-weight: 700;
        font-size: 0.9rem;
        line-height: 1rem;
        cursor: pointer;
        @media screen and (max-width: 1700px) {
            font-size: 14px;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .bet-item {
            width: auto;
            min-height: 46px;
            position: relative;
            display: flex;
            align-items: center;
            border-radius: 12px;
            padding-right: 10px;
            background: #1B1F2A;

            &:not(:first-child) {
                margin-top: 10px;
            }

            &:not(:last-child) {
                padding-bottom: 5px;

                &:after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    top: 110%;
                    background-color: #2A2E3D;
                }
            }

            span {
                color: #FFFFFF;
                font-size: 0.6rem;
                line-height: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .bet-value {
                display: flex;
                align-items: center;
                margin-left: auto;
            }

            .bet {
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                flex: 1;
                display: flex;
                justify-content: flex-end;
                margin-left: 6px;
                font-weight: 700;
                &.green {
                    color: #00CE78;
                }
                &.red {
                    color: #D7392F;
                }
            }

            svg {
                margin-left: 12px;
                margin-bottom: 2px;
            }

            &.is-me {
                background: lighten(#1B1F2A, 10%);
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 5px;
                &:after {
                    content: '';
                    display: none;
                }
            }
        }
    }
    &.red {
        .streetrace-column-btn {
            background: #F05F31;
            color: #141720;

            &.winner {
                box-shadow: 0px 0px 7px 4px #F05F31;
            }

            &:disabled {
                cursor: default;
                color: #141720;
                background-color: rgb(126, 122, 122);
                box-shadow: unset;
            }
        }
    }
    &.yellow {
        .streetrace-column-btn {
            color: #141720;
            background: #FFC700;

            &.winner {
                box-shadow: 0px 0px 7px 4px #FFC700;
            }

            &:disabled {
                cursor: default;
                color: #141720;
                background-color: rgb(126, 122, 122);
                box-shadow: unset;
            }
        }
    }

    &.blue {
        .streetrace-column-btn {
            color: #141720;
            background: #4B68FF;

            &.winner {
                box-shadow: 0px 0px 7px 4px #4B68FF;
            }

            &:disabled {
                cursor: default;
                color: #141720;
                background-color: rgb(126, 122, 122);
                box-shadow: unset;
            }
        }
    }

    &.green{
        .streetrace-column-btn {
            background: #46BE60;
            color: #141720;
            &.winner {
                box-shadow: 0px 0px 7px 4px #46BE60;
            }

            &:disabled {
                cursor: default;
                color: #141720;
                background-color: rgb(126, 122, 122);
                box-shadow: unset;
            }
        }
    }
}


@media screen and (min-width: 2600px ) {
    .streetrace-column {
        width: 400px;

        &-btn {
            height: 60px;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: 1700px) {
    .streetrace-column {

        &-btn {
            height: 40px;
        }
    }
}

@media (max-width: 1430px) {
    .streetrace-column {
        max-width: none;
        min-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .streetrace-column {
        padding: 10px 10px 0;
        min-width: unset;

        &-x {
            margin-bottom: 8px;
        }

        &-btn {
            margin-bottom: 13px;
            font-size: 0.6rem;
            height: 40px;
        }

        &-list {
            display: none;
        }
    }
}