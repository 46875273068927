.deposit {
  display: grid;
  gap: 16px;

  &__back {
    width: 24px;
    height: 24px;
  }

  &__header {
    display: flex;
    align-items: center;

    & span {
      margin-left: 12px;
      font-weight: 700;
      font-size: 24px;
      color: #E8E13F;
    }
  }
}