.streetrace-info{
    width: 500px;
    padding: 56px 36px 36px;
    background: #1B1F2A;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-header{
        font-size: 1.1rem;
        line-height: 1rem;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-bottom: 24px;
    }
    &-body{
        font-size: 0.7rem;
        line-height: 1rem;
        color: rgba(255, 255, 255, 0.4);
        width: 100%;
        padding-right: 10px;
        &::-webkit-scrollbar {
            background: transparent;
            width: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            background-color: #fff;
            border-radius: 0px;
        }
    }
}