.deposit-crypto {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(51, 57, 74, 0.55), rgba(51, 57, 74, 0.55));
  z-index: 12;

  & p {
    font-size: 10px;
    line-height: 1.55;
    color: rgba(255, 255, 255, 0.6);
  }

  & span {
    font-weight: 600;
    font-size: 10px;
    line-height: 1.4;
    color: #E8E13F;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__qr {
    padding: 5px;
    width: 89px;
    height: 89px;
    border-radius: 6.7px;
    background-color: #fff;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__popup {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    height: 500px;
    z-index: 11;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__section {
    padding: 28px 27px;
    background-color: #1B1F2A;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__logo {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 221, 81, 0.1);
    border-radius: 20px;
  }

  &__title {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
  }

  &__info {
    padding: 2px 8px;
    top: 6px;
    right: 6px;
    font-weight: 700;
    font-size: 10px;
    color: #57C695;
    background-color: rgba(87, 198, 149, 0.1);
    border-radius: 7px;

    &.green {
      color: #57c695;
      background-color: rgba(87, 198, 149, 0.1);
    }

    &.red {
      background-color: rgba(246, 68, 45, 0.1);
      color: rgb(246, 68, 45);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.1);
      color: rgb(84, 111, 255);
    }

    &.purple {
      background-color: rgba(108, 84, 255, 0.1);
      color: rgb(108, 84, 255);
    }

    &.orange {
      background-color: rgba(255, 169, 73, 0.1);
      color: rgb(255, 169, 73);
    }

    &.yellow {
      background-color: rgba(232, 225, 63, 0.1);
      color: rgb(232, 225, 63);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.1);
      color: rgb(84, 111, 255);
    }
  }

  &__title {
    &.green {
      color: rgb(87, 198, 149);
      & .deposit-crypto__logo {
        background-color: rgba(87, 198, 149, 0.1);
      }
    }

    &.red {
      color: rgb(246, 68, 45);
      & .deposit-crypto__logo {
        background-color: rgba(246, 68, 45, 0.1);
      }
    }

    &.blue {
      color: rgb(84, 111, 255);
      & .deposit-crypto__logo {
        background-color: rgba(84, 111, 255, 0.1);
      }
    }

    &.purple {
      color: rgb(108, 84, 255);
      & .deposit-crypto__logo {
        background-color: rgba(108, 84, 255, 0.1);
      }
    }

    &.orange {
      color: rgb(255, 169, 73);
      & .deposit-crypto__logo {
        background-color: rgba(255, 169, 73, 0.1);
      }
    }

    &.yellow {
      color: rgb(255, 221, 81);
      & .deposit-crypto__logo {
        background-color: rgba(255, 221, 81, 0.1);
      }
    }
  }

  &__make {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(270deg, rgba(38, 42, 55, 0.5) 0%, rgba(38, 42, 55, 0) 100%);
    border-radius: 12px;

    & p:first-child {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }
  }

  &__input {
    position: relative;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 7px;
  }

  &__copy {
    padding: 13px 34px 13px 16px;
    width: 340px;
    background: #1B1F2A;
    border: 1px solid #2A2E3D;
    border-radius: 8px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 12px;
    opacity: 0.6;
    &-button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  & .note {

    & p {
      margin-bottom: 8px;
      margin-left: 33px;

      & span {
        font-weight: 400;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }

    &__alert {
      margin-bottom: 8px;
      margin-right: 8px;
      display: inline-block;
      padding: 3px 10px;
      font-weight: 800;
      font-size: 12.5px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.6);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
    }
  }

  &__convert {
    padding: 23px 25px;
    background: url("../../../assets/deposit/rocket.png") right 18px top 14px/ 23% no-repeat,
    url("../../../assets/deposit/bg-popup.svg") right bottom / contain no-repeat,
    #1B1F2A;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
  }

  &__conversion {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__equal {
    padding: 18px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2A2E3D;
    border: 1px solid #2A2E3D;
    border-radius: 8px;
  }

  &__currency {
    padding: 13px 15px;
    display: flex;
    align-items: center;
    width: 30%;
    background-color: #1B1F2A;
    border: 1px solid #2A2E3D;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.6;

    &-fuel {
      flex: 1 1 auto;
      width: 70%;
      margin-left: 5px;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      opacity: 0.6;
    }
  }

  &__mobile-header {
    display: none;
  }
}

@media (max-width: 600px) {
  .wrapper-content {
    // position: relative;
  }

  .deposit-crypto {
    &__popup {
      width: 100%;
      height: 100%;
      background: url("../../../assets/deposit/rocket.png") right bottom / 130px no-repeat,
      #141720;
    }

    &__close {
      display: none;
    }

    &__title {
      display: none;
    }

    &__mobile-header {
      display: flex;
      align-items: center;
      gap: 12px;

      & span {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
      }
    }

    &__section {
      padding: 24px 15px 15px;
      background: transparent;
      box-shadow: none;

    }

    &__make {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      box-shadow: none;
    }

    &__qr {
      margin: 0 auto;
    }

    & .note {
      & p {
        margin-left: 0;
        margin-bottom: 0;
        padding: 16px 16px 8px;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px 8px 0 0;

        &:last-child {
          padding-top: 0;
          padding-bottom: 16px;
          border-radius: 0 0 8px 8px;
        }
      }
    }

    &__convert {
      margin: 0 15px 15px;
      padding: 16px 16px 31px;
      background: #1B1F2A;
      border-radius: 12px;
      box-shadow: none;
    }

    &__conversion {
      width: 100%;
    }

    &__currency {
      flex: 1 1 auto;
    }
  }
}

@media (max-width: 480px) {
  .deposit-crypto {
    &__qr {
      margin: 0;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__copy {
      width: 236px;
    }
  }
}

//
//@media (max-width: 600px) {
//  .deposit-crypto {
//    &__close {
//      right: 45px;
//    }
//
//    &__popup {
//      padding: 0 10px;
//      width: auto;
//      height: auto;
//    }
//
//    &__make {
//      flex-direction: column;
//      align-items: flex-start;
//      gap: 10px;
//    }
//
//    &__copy,
//    &__inner,
//    &__input {
//      width: 100%;
//    }
//
//    &__qr {
//      display: none;
//    }
//
//    &__currency {
//      flex: 1 1 auto;
//    }
//
//    &__convert {
//      background: url("../../../assets/deposit/bg-popup.svg") right bottom / contain no-repeat,
//      #1B1F2A;
//    }
//
//    &__section,
//    &__convert {
//      padding: 15px;
//    }
//  }
//}