.race-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(51, 57, 74, 0.55), rgba(51, 57, 74, 0.55));
  z-index: 12;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__popup {
    padding: 24px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 413px;
    height: 257px;
    background-color: #1B1F2A;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 11;

    @media (max-width: 425px) {
      width: 310px;
      height: 300px;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__buttons {
    position: absolute;
    bottom: 25px;
    width: 353px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 425px) {
      width: 264px;
      gap: 5px;
    }
  }

  &__button {
    width: 168px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    @media (max-width: 425px) {
      width: auto;
      flex: 1;
    }

    &-cancel {
      color: rgba(255, 255, 255, 0.6);
      background: #2A2E3D;

    }

    &-delete {
      background: rgba(240, 95, 49, 0.1);
      color: #F05F31;
    }
  }

  &__title {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 15px;

    & span {
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 155%;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);

  }

  &__user {
    margin-left: 30px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: flex-end;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 1px solid black;
    margin-right: 9px;
    padding: 1px;
    border-radius: 5px;
    img {
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }
    &.grey {
      outline-color: #A3A5B7;
    }
  
    &.blue {
      outline-color: #93C7F4;
    }
  
    &.yellow {
      outline-color: #E8E13F;
    }
  
    &.orange {
      outline-color: #E8903F;
    }
  
    &.violet {
      outline-color: #BC93FE;
    }
  
    &.red {
      outline-color: #E8533F;
    }
  
    &.fire {
      outline-color: #FD6228;
    }
  }

}