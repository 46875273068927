.crypto-card {
  padding: 10px 10px 10px 28px;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 70px;
  background-color: #262A37;
  border-radius: 8px;
  overflow: hidden;
  transition: all .3s ease;
  cursor: pointer;

  &__name {
    margin-left: 36px;
    padding-left: 8px;
    border-left: 2px solid #fff;
    font-weight: 700;
    color: #FFFFFF;
  }

  &__info {
    position: absolute;
    padding: 2px 8px;
    top: 6px;
    right: 6px;
    font-weight: 700;
    font-size: 10px;
    color: #57C695;
    background-color: rgba(87, 198, 149, 0.1);
    border-radius: 7px;

    &.green {
      color: #57c695;
      background-color: rgba(87, 198, 149, 0.1);
    }

    &.red {
      background-color: rgba(246, 68, 45, 0.1);
      color: rgb(246, 68, 45);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.1);
      color: rgb(84, 111, 255);
    }

    &.purple {
      background-color: rgba(108, 84, 255, 0.1);
      color: rgb(108, 84, 255);
    }

    &.orange {
      background-color: rgba(255, 169, 73, 0.1);
      color: rgb(255, 169, 73);
    }

    &.yellow {
      background-color: rgba(232, 225, 63, 0.1);
      color: rgb(232, 225, 63);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.1);
      color: rgb(84, 111, 255);
    }
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    &.green {
      & path {
        fill: rgb(87, 198, 149);
      }
    }

    &.red {
      & path {
        fill: rgb(246, 68, 45);
      }
    }

    &.blue {
      & path {
        fill: rgb(84, 111, 255);
      }
    }

    &.purple {
      & path {
        fill: rgb(108, 84, 255);
      }
    }

    &.orange {
      & path {
        fill: rgb(255, 169, 73);
      }
    }

    &.yellow {
      & path {
        fill: rgb(232, 225, 63);
      }
    }

    &.blue {
      & path {
        fill: rgb(84, 111, 255);
      }
    }

  }

  &:hover {

    &.green {
      background-color: rgba(87, 198, 149, 0.2);
    }

    &.red {
      background-color: rgba(246, 68, 45, 0.2);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.2);
    }

    &.purple {
      background-color: rgba(108, 84, 255, 0.2);
    }

    &.orange {
      background-color: rgba(255, 169, 73, 0.2);
    }

    &.yellow {
      background-color: rgba(232, 225, 63, 0.2);
    }

    &.blue {
      background-color: rgba(84, 111, 255, 0.2);
    }
  }
}

@media (max-width: 1250px) and (min-width: 1024px), (max-width: 650px) {
  .crypto-card {
    min-height: 70px;
  }
}

@media (max-width: 470px) {
  .crypto-card {
    &__name {
      margin-left: 10px;
    }
  }
}