.streetrace {
    display: flex;
    flex-direction: column;

    &-stat {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 18px;

        &-history {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 280px;
            overflow: hidden;

            span {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                margin-bottom: 8px;
            }

            .history {
                display: flex;
                align-items: center;

                li {
                    border-radius: 5px;
                    height: 16px;
                    width: 16px;
                    cursor: pointer;
                    margin-right: 6px;

                    &.green {
                        background: #46BE60;
                    }

                    &.red {
                        background: #F05F31;
                    }

                    &.blue {
                        background: #4B68FF;
                    }

                    &.yellow {
                        background: #FFC700;
                    }
                }
            }
        }

        &-last {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 1025px) and (max-width: 1130px), (max-width: 475px) {
                flex-direction: column;
            }

            &-span {
                color: rgba(255, 255, 255, 0.6);
                font-size: 0.6rem;
                line-height: 0.8rem;
                font-weight: bold;
                margin-left: auto;

                @media (min-width: 1025px) and (max-width: 1130px), (max-width: 475px) {
                    padding-bottom: 7px;
                }
            }

            .values {
                display: flex;
                align-items: center;

                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 16px;

                    span {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                        font-weight: bold;
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        padding: auto;
                    }

                    .color {
                        border-radius: 5px;
                        height: 16px;
                        width: 16px;
                        margin-right: 6px;

                        &.green {
                            background: #46BE60;
                        }

                        &.red {
                            background: #F05F31;
                        }

                        &.blue {
                            background: #4B68FF;
                        }

                        &.yellow {
                            background: #FFC700;
                        }
                    }
                }
            }
        }
    }

    &-bets {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        margin-top: 45px;
        margin-bottom: 15px;
    }
}


@media (max-width: 1430px) {
    .streetrace-bets {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
}

@media (max-width: 1250px) {
    .streetrace {
        &-stat {
            padding-top: 12px;
            margin-bottom: 60px;

            &-history {
                span {
                    font-size: 0.5rem;
                    line-height: 0.7rem;
                }

                .history {
                    max-width: 180px;
                    overflow: hidden;

                    li {
                        height: 12px;
                        width: 12px;
                        min-width: 12px;
                    }
                }
            }

            &-last {
                span {
                    font-size: 0.5rem;
                    line-height: 0.7rem;
                }

                .values {
                    display: flex;
                    align-items: center;
                    gap: 0;

                    &-item {
                        margin-left: 8px;

                        .color {
                            height: 12px;
                            width: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .streetrace {
        &-bets {
            width: 100%;
            flex-wrap: wrap;
        }
    }
}

@media screen and (min-width: 2600px) {
    .streetrace {
        &-bets {
            margin: 45px auto 15px;
        }
    }
}

@media screen and (max-width: 1025px) {
    .streetrace {
        &-bets {
            border-radius: 8px;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin: 0px 0 20px;
        }
    }
}

.streetrace .timer{
    top: 80px;

    @media (max-width: 1130px) {
        top: 97px;
    }

    @media (max-width: 1025px) {
        top: 65px;
    }

    @media (max-width: 475px) {
        top: 87px;
    }
}