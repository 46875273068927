.game-nav {
    display: flex;
    align-items: center;
    width: 100%;
    background: #1B1F2A;
    border-radius: 12px;
    height: 55px;
    padding: 6px 4px;
    position: sticky;
    top: 0px;
    z-index: 11;

    &-item {
        padding: 10px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-radius: 12px;

        svg {
            margin-right: 16px;

            path {
                fill: rgba(255, 255, 255, 0.2);
            }
        }

        span {
            color: rgba(255, 255, 255, 0.2);
            font-size: 0.7rem;
            line-height: 1rem;
            font-weight: bold;
        }

        &:hover {
            background: rgba(42, 46, 61, 0.4);

            & span {
                color: #EAEAEA;
            }

            svg {
                path {
                    fill: #EAEAEA;
                }
            }
        }


        &.active {
            background: #E8E13F;

            span {
                color: #141720;
            }

            svg {
                margin-right: 16px;

                path {
                    fill: #141720;
                }
            }
        }
    }
}

@media (max-width: 1362px) {
    & .game-nav {
        .game-nav-item {
            padding: 5px;
        }
    }

}

@media (max-width: 1312px) and (min-width: 1024.9px) {

    .game-nav-item,
    .game-nav-item.active {
        svg {
            margin-right: 0;
        }

        span {
            display: none;
        }
    }
}

@media screen and (max-width: 1024px) {
    .game-nav {
        display: none;
    }
}
