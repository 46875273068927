.race {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
    }

    &__buttons {
        display: flex;
        margin-top: 20px;
    }

    &__button {
        display: flex;
        align-items: center;

        &-cancel {
            position: relative;
            padding: 9.5px 12px 9.5px 34px;
            background: rgba(240, 95, 49, 0.1);
            border-radius: 8px;
            font-weight: 700;
            font-size: 10px;
            line-height: 18px;
            color: #E8533F;
            -moz-user-select: none;
            -khtml-user-select: none;
            user-select: none;
            cursor: pointer;

            &::before {
                content: '';
                background: url(../../src/assets/trash-can.svg);
                background-repeat: no-repeat;
                height: 18px;
                width: 18px;
                position: absolute;
                top: 11px;
                left: 16px;
            }
        }
    }

    &-no-games {
        margin: 80px auto;
        width: 312px;
        padding-top: 201px;
        background: url("../assets/race/bg-no-games.png") top center / 252px 197px no-repeat;

        & p {
            font-weight: 700;
            font-size: 20px;
            line-height: 1.5;
            text-align: center;
            color: #FFFFFF;

            &:last-child {
                margin-top: 8px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: rgba(255, 255, 255, 0.6);

            }
        }
    }

    &-game-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1250px) {
            margin-top: 10px;
        }

        & .game-header {
            padding: 0;
        }

        & .buttons {

            & .race-game-create-bot {
                padding: 8px 17px;
            }
        }
    }

    &-delete {
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        background: rgba(240, 95, 49, 0.1);
        border-radius: 8px;

        & span {
            font-weight: 700;
            font-size: 10px;
            color: #E8533F;
        }
    }

    &-create {
        font-size: 0.5rem;
        font-weight: bold;
        margin-left: 10px;

        &-bot {
            color: #E8E13F;
            font-size: 10px;
            font-weight: 700;
            display: flex;
            align-items: center;
            padding: 9px 17px;
            margin: 0 10px 0 7px;
            border: 1px solid #E8E13F;
            border-radius: 8px;
            background: rgba(232, 225, 63, 0.1);

            @media (max-width: 1500px) {
                margin-left: 0;
            }
            & img {
                margin-right: 7px;
            }
        }
    }

    &-games {
        width: 100%;
        background: #1B1F2A;
        border-radius: 12px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 185px;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        margin-top: 30px;
        padding: 30px;
    }

    & .disable {
        position: relative;
        padding: 5px;

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(20, 23, 32, 0.7);
            backdrop-filter: blur(5px);
            border-radius: 12px;
            z-index: 10;
        }
    }

}



@media screen and (min-width: 1920px) {
    .race {
        &-games {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media screen and (max-width: 1500px) {
    .race {

        &-create {
            flex: 1;
            justify-content: center;
        }

        & .game-amount-buttons {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1420px) {
    .race-games {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1220px) {
    .race-games {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 1024px) {
    .race {
        display: flex;
        flex-direction: column;
        // position: relative;
        padding-bottom: 20px;

        &__buttons {
            margin-top: 0;
        }

        &-create {
            //width: 100%;
            //left: calc(50% - 122px);
            //margin-top: 0px;
            //margin-bottom: 18px;
            //margin-left: 0px;
            //min-width: unset;
        }

        &-games {
            background: transparent;
            border-radius: 0px;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 14px;
            grid-row-gap: 14px;
            margin-top: 26px;
            padding: 0px;
        }
    }
}

@media (max-width: 767.9px) {
    .race-games {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 475px) {
    .race {
        &-games {
            grid-template-columns: repeat(1, 1fr);
        }

        &__button-cancel {
            padding: 9px 9px 9px 34px;
        }

        &-create-bot {
            padding: 9px;
        }
    }
}