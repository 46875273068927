@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-ExtraLight.woff") format("woff"),
         url("/fonts/Hauora-ExtraLight.woff2") format("woff2");
    font-weight: 100;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-Light.woff") format("woff"),
         url("/fonts/Hauora-Light.woff2") format("woff2");
    font-weight: 300;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-Regular.woff") format("woff"),
         url("/fonts/Hauora-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-Medium.woff") format("woff"),
         url("/fonts/Hauora-Medium.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-SemiBold.woff") format("woff"),
         url("/fonts/Hauora-SemiBold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-Bold.woff") format("woff"),
         url("/fonts/Hauora-Bold.woff2") format("woff2");
    font-weight: 700;
}

@font-face {
    font-family: "Hauora";
    src: url("/fonts/Hauora-ExtraBold.woff") format("woff"),
         url("/fonts/Hauora-ExtraBold.woff2") format("woff2");
    font-weight: 900;
}

@font-face {
  font-family: LCD;
  src: url("/fonts/lcd.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
    font-family: 'Futura';
        
        src:    url('https://nomail.com.ua/files/eot/0504798a34e39ae72fc35f375fe35744.eot?#iefix') format('embedded-opentype'),
                url('https://nomail.com.ua/files/woff/0504798a34e39ae72fc35f375fe35744.woff') format('woff'),
                url('https://nomail.com.ua/files/woff2/0504798a34e39ae72fc35f375fe35744.woff2') format('woff2');
    }
    
    @font-face { font-family: 'Futura'; font-weight: 900;  src: local('BeFutura-Bold'), url('https://nomail.com.ua/files/woff/0504798a34e39ae72fc35f375fe35744.woff') format('woff'); }
    