.ticket-create{
    width: 544px;
    padding: 30px 25px 25px;
    background: #1B1F2A;
    &-form {
        display: flex;
        flex-direction: column;
    }
    &-header{
        color: #E8E13F;
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: bold;
        margin-bottom: 3px;
    }
    &-title{
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        margin-bottom: 16px;
    }
    &-select-title {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.6);
    }
    .select{
        height: 43px;
        &-active{
            display: grid;
            grid-template-rows: 0px;
            grid-template-columns: 90% 1fr;
            height: 43px;
            .title{
                grid-column-start: 1;
                grid-row-start: 1;
    
            }
            span{
                grid-column-start: 1;
                grid-row-start: 2;
            }
            svg{
                margin-left: auto;
                margin-top: 40px;
            }
        }
        &.active {
            & .select{
                &-active {
                    box-shadow: 0px 4px 20px #141720;
                    border-radius: 8px 8px 0 0;
                    border-bottom: none;
                    background-color: #1B1F2A;
                }
            }
        }
        &-list{
            top: 43px;
            border: 1px solid #2A2E3D;
            border-top: none;
            box-shadow: 0px 15px 20px #141720;
            border-radius: 0 0 8px 8px;
            & li {
                margin: 0 6px;
                border-radius: 4px;
                &:first-child {
                    margin-top: 10px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        top: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 1px;
                        width: 100%;
                        background-color: #2A2F3D;
                    }
                }
                &:last-child {
                    margin-bottom: 10px;
                }
                &:hover {
                    background: #2A2E3D;
                }
            }
        }
    }
    textarea {
        width: 100%;
        height: 150px;
        border: 1px solid #2A2E3D;
        border-radius: 8px;
        padding: 12px;
        color: #FFFFFF;
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;
        margin-bottom: 16px;
        margin-top: 0px;
        &::placeholder{
            color: rgba(255, 255, 255, 0.6);
        }
    }
    &-submit{
        width: 100%;
        height: 44px;
        align-self: flex-end;
        color: #141720;
        font-size: 0.7rem;
        font-weight: bold;
    }
}

@media screen and (max-width: 1024px) {
    .ticket-create{
        padding: 20px;
        width: 100%;
        textarea {
            height: 250px;
        }
    }
}