.mines {
  & .join {
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #49D167;
    border-radius: 8px;

    & .join__content {
      display: flex;
      align-items: center;
      margin: 0 auto;
    }

    @media (max-width:1280px) and (min-width: 1024px), (max-width:700px) {
      grid-area: 3 / 1 / 4 / 3;

    }

    & span {
      font-weight: 800;
      font-size: 10px;

    }

    &__text {
      padding-left: 10px;
      color: #141720;
    }

    &__bet {
      margin-left: 10px;
      padding: 2px 5px;
      display: flex;
      align-items: center;
      gap: 2px;
      border-radius: 5px;
      background-color: #1B1F2A;
      color: #FFC700;
    }

    &__img {
      width: 16px;
      height: 16px;
    }
  }
}