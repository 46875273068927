@import "../../Bonuses/levelColor";

.depend {
  overflow: hidden;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__toggle {
    height: 18px;
    width: 18px;
    transform: rotate(-180deg);
    transition: all .3s ease;

    @media (min-width: 1024px) {
      display: none;
    }

    &.active {
      transform: rotate(0deg);
    }
  }

  &__list {
    margin-top: 16px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    max-height: 300px;

    &.active {
      margin-top: 16px;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }

    @media (min-width: 1024px) {
      padding-bottom: 10px;
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 1520px) {
    & {
      grid-column-start: 1;

    }
  }
}

.daily__depend .depend__list {
  margin-top: 16px !important;
}