.deposit {
  & .refferal {
    padding: 16px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1B1F2A;
    border-radius: 12px;

    &__text {
      font-size: 12px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.6);

      & p:first-child {
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 8px;
      }
    }

    &__form {
      display: flex;
      align-items: center;
      gap: 18px;
    }

    &__input-form {
      position: relative;
    }

    &__input {
      padding: 10px 16px;
      background-color: rgba(27, 31, 42, 0.3);
      border: 1px solid #2A2E3D;
      border-radius: 8px;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.33;
      color: rgba(255, 255, 255, 0.6);
    }

    &__change-button {
      padding: 10px 14px;
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
    }

    &__button {
      padding: 10px 30px;
      background: #46BE60;
      border-radius: 8px;
      font-weight: 700;
      font-size: 10px;
      color: #141720;
      line-height: 14px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1200px) and (min-width: 1024px), (max-width: 580px){
  .deposit {
    & .refferal {
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;

      &__form {
        width: 100%;
      }

      &__input {
        width: 100%;

        &-form {
          flex: 1 1 auto;
        }
      }
    }
  }
}