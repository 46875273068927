.game-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    height: 58px;
    &-wrapper{
        gap: 10px;
        color: #E8E13F;
        font-size: 1.2rem;
        line-height: 1.15rem;
        font-weight: bold;
        display: flex;
        align-items: center;

        & h2 {
            font-size: 24px;
        }

        svg {
            width: 0.9rem;
            height: 0.9rem;
        }
    }
    .timer{
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(75, 104, 255, 0.1);
        border-radius: 8px;
        height: 38px;
        padding: 0px 14px;
        min-width: 230px;
        svg{
            margin-right: 13px;
        }
        span{
            color: #FFFFFF;
            font-size: 0.8rem;
            line-height: 1.1rem;
        }
    }
    .bankroll{
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.6rem;
        line-height: 0.8rem;
        font-weight: bold;
        .fuel{
            display: flex;
            align-items: center;
            margin-left: 8px;
            svg{
                margin-bottom: 2px;
            }
            span{
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                margin-left: 5px;
            }
        }
    }
}

@media (max-width: 1250px) {
    .game-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        height: auto;
        position: relative;
        h1{
            font-size: 0.9rem;
            svg{
                width: 20px;
                height: 20px;
                margin-left: 6px;
                margin-top: 0px;
            }
        }
        .timer{
            width: 100%;
            position: absolute;
            top: 97px;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 1024px) {
    .game-header{
        padding-top: 0px;
        h1{
            .info{
                &-popup{
                    left: -105px;
                }
            }
        }
        .timer{
            top: 73px;
        }
    }
}