.speedometr {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  height: 270px;
  width: 305px;
  display: flex;
  align-items: end;

  @media (max-width: 1350px) and (min-width: 1024.9px), (max-width: 767px) {
    left: 50%;
    transform: translateX(-50%);
  }
  @mixin anim($time,$delay, $animName) {
    animation-duration: $time;
    animation-name: $animName;
    animation-iteration-count: infinite;
    animation-delay: $delay
  }

  & .circle {
    position: absolute;

    &-1{
      top: 0px;
      left: -65px;
      transform: scale(0.8);
      @include anim(2s, 0.2s, pulse)
    }
    &-2{
      top: -24px;
      left: -87px;
      transform: scale(0.8);
      @include anim(2s, 0.3s, pulse)
    }
    &-3{
      top: -46px;
      left: -97px;
      transform: scale(0.8);
      @include anim(2s, 0.4s, pulse)
    }
    &-4{
      top: -70px;
      left: -114px;
      transform: scale(0.8);
      @include anim(2s, 0.5s, pulse)
    }
    &-5{
      top: -90px;
      left: -146px;
      transform: scale(0.8);
      @include anim(2s, 0.6s, pulse)
    }
  }

  @keyframes pulse {
    0% {
      //transform: translateY(1px);
      transform: scale(0.8);
    }
    50% {
      //transform: translateY(-2px);
      transform: scale(0.83);
    }
    100% {
      //transform: translateY(1px);
      transform: scale(0.8);
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .circle__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .speedometr-sec {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 13px;
  }

  .speed-value {
    position: absolute;
    color: #FFFFFF;
    font-size: 16px;
    font-family: LCD;

    &-1 {
      left: 38px;
      bottom: 23px;
    }

    &-2 {
      left: 46px;
      bottom: 67px;
    }

    &-3 {
      left: 67px;
      bottom: 100px;
    }

    &-4 {
      left: 94px;
      bottom: 122px;
    }

    &-5 {
      left: 140px;
      bottom: 133px;
    }

    &-6 {
      left: 181px;
      bottom: 122px;
    }

    &-7 {
      left: 207px;
      bottom: 100px;
    }

    &-8 {
      left: 234px;
      bottom: 67px;
    }

    &-9 {
      left: 240px;
      bottom: 23px;
    }

    &-xl {
      &.speed-value-1 {
        left: 40px;
        bottom: 23px;
      }

      &.speed-value-2 {
        left: 50px;
        bottom: 67px;
      }

      &.speed-value-3 {
        left: 67px;
        bottom: 97px;
      }

      &.speed-value-4 {
        left: 105px;
        bottom: 122px;
      }

      &.speed-value-5 {
        left: 149px;
        bottom: 133px;
      }

      &.speed-value-6 {
        left: 197px;
        bottom: 122px;
      }

      &.speed-value-7 {
        left: 232px;
        bottom: 97px;
      }

      &.speed-value-8 {
        left: 241px;
        bottom: 67px;
      }

      &.speed-value-9 {
        left: 245px;
        bottom: 23px;
      }
    }
  }

  .speedometr-divider {
    position: absolute;
    z-index: 100;
    bottom: 40px;
    left: 15px;
  }
  .speedometr-guides {
    position: absolute;
    width: 279px;
    height: 132px;
    z-index: 100;
    bottom: 40px;
    left: 15px;
  }
  .speedometr-guides-1 {
    position: absolute;
    width: 279px;
    height: 132px;
    z-index: 100;
    bottom: 30px;
    left: 15px;
  }

  .speedometr-bg {
    position: absolute;
    width: 120%;
    bottom: 0;
    left: -29px;
  }

  .speed-gradient {
    position: absolute;
    bottom: 32px;
    left: 14px;

    path {
      transform-origin: bottom;
      transition: .3s ease-out;
    }
  }

  .speedometr-value {
    width: fit-content;
    margin: 60px auto 30px;
    position: relative;
  }

  .speedometr-point {
    // position: absolute;
    height: 6px;
    margin-left: -1px;
    margin-right: 2px;
  }
}