.old {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 415px;
    background: #1B1F2A;
    border-radius: 16px;
    padding: 30px;
    padding-top: 0px;
    overflow: hidden;
    &.disabled{
        .close-popup{
            display: none;
        }
    }
    &-body{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 24px;
        svg{
            width: 300px;
            height: 110px;
            // height: 100px;
            min-height: 78px;
            margin-left: -44px;
        }
        .title{
            color: rgba(255, 255, 255, 0.6);
            font-size: 0.7rem;
            line-height: 0.9rem;
            text-align: start;
        }
    }
    &-nav{
        width: 100%;
        &-check {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            width: 100%;
            span {
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
            .check {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: 2px solid rgba(255, 255, 255, 0.4);
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.1);
                transition-duration: .2s;
                margin-right: 16px;
                span{
                    opacity: 0;
                    transition-duration: .2s;
                    background: #E8E13F;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    svg{
                        width: 60%;
                        path{
                            fill: #1B1F2A;
                        }
                    }
                }
                &.active {
                    border: 2px solid #E8E13F;
                    span {
                        opacity: 1;
                    }
                }
            }
        }
        .accept-check {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            width: 100%;
            margin-top: 16px;
            .title{
                color: #FFFFFF;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
                a{
                    color: #E8E13F;
                    text-decoration: underline;
                }
            }
            .check {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: 2px solid rgba(255, 255, 255, 0.4);
                background: rgba(255, 255, 255, 0.1);
                border-radius: 5px;
                transition-duration: .2s;
                margin-right: 16px;
                span{
                    opacity: 0;
                    transition-duration: .2s;
                    background: #E8E13F;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 60%;
                        path{
                            fill: #1B1F2A;
                        }
                    }
                }
                &.active {
                    border: 2px solid #E8E13F;
                    span {
                        opacity: 1;
                    }
                }
            }
        }
        & > .account-btn{
            width: 100%;
            margin-top: 24px;
            transition-duration: .2s;
            width: 200px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: #E8E13F;
            span{
                margin-left: 15px;
                color: #141720;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
            &.disabled{
                background: rgba(255, 255, 255, 0.1);
            } 
        }
    }
}