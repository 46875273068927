.mines {

  &__turn {
    margin-left: 8px;
  }
  &__player {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 15px;
    @media (max-width: 1280px) and (min-width: 1024px), (max-width:700px) {
      grid-area: 1 / 1 / 2 / 2;
    }
    
    & .bets-avatar {
      margin: 0;
    }
    
    &.two {
      margin-right: 0;
      flex-direction: row-reverse;
      text-align: right;

      @media (max-width: 1280px)  and (min-width: 1024px), (max-width:700px) {
        grid-area: 1 / 2 / 2 / 3;
      }

      & .mines__bet {
        justify-content: flex-end;
      }

      & .mines__turn {
        margin-left: 0px;
        margin-right: 8px;
        order: -1;
      }
    }

    &.waiting {
      & .bets-avatar {
        & .level {
          background: url('../../../assets/no-level.svg') center center no-repeat, #494C55;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__bet {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;

    .loose {
      color: #F05F31;
    }

    .win {
      color: #49D167;
    }
  }

  &__turn {
    padding: 3px 16px;
    background: rgba(232, 225, 63, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #E8E13F;

    &.not-move {
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &__nickname {
    width: 145px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-wrap:wrap-reverse;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
    @media (max-width: 1155px) and (min-width: 1025px) {
      width: 100px;
    }
    @media (max-width:680px) {
      width:74px;
    }
  }
}
