@import '../../fontSize';

.statistic {
  font-family: 'Hauora';
  color: #FFFFFF;
  padding: 25px;
  border-radius: 12px;
  background-color: #1B1F2A;
  flex: 0 1 40%;

  &__title {
    font-size: $titleSize;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
  }
}

@media (max-width: 425px) {
  .statistic {
      &__block {
      padding: 10px;
    }
  }
}