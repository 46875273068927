.bonuses__title {
  color: #E8E13F;
  font-family: 'Hauora';
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.bonuses__wrapper {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & .card {
    position: relative;
    overflow: hidden;
    flex: 1 1 40%;
    height: 280px;
    min-width: 280px;
    border-radius: 12px;
    padding: 41px 34px;

    box-shadow: none;
    transform: translateY(0);
    transition: .2s transform ease, .15s box-shadow ease-in;
    &-hover:hover {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
      transform: translateY(-1px);
    }
    &__title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 23px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    &__text {
      white-space: pre-wrap;
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.6);
      max-width: 70%;

    }
    &__subtitle {
      display: flex;
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  & .bonuses__card-achievements {
    overflow: visible;
  }
}

@media (max-width: 1520px) and (min-width: 1024.9px), (max-width: 500px){
  .bonuses__wrapper {
    & .card {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
      }
      &__text {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .bonuses__wrapper {
    .card {
      &__text {
        max-width: none;
      }
      .information {
        & svg {
          margin: 0;
        }
        &__popup {
          left: -110px;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .bonuses__wrapper {
    .card {
      padding: 20px;
      min-width: 345px;
      max-height: 162px;
      &__title {
        margin-bottom: 12px;
        font-size: 0.8rem;
      }
      &__text {
        font-size: 0.5rem;
      }
    }
  }
}