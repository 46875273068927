.tickets{
    display: flex;
    flex-direction: column;
    width: 100%;
    &-header{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 24px;
        a{
            display: flex;
            align-items: center;
            margin-right: 12px;
        }
        h2{
            color: #E8E13F;
            font-size: 1.2rem;
            line-height: 1.65rem;
        }
        .create{
            width: 185px;
            height: 41px;
            color: #141720;
            font-size: 0.7rem;
        }
    }
    &-info{
        display: flex;
        flex-direction: column;
        background: #1B1F2A;
        border-radius: 12px;
        padding: 17px 16px 24px;
        margin-bottom: 12px;
        h3{
            display: flex;
            align-items: center;
            color: #E8E13F;
            font-size: 0.7rem;
            line-height: 1rem;
            font-weight: normal;
            margin-bottom: 12px;
            svg{
                margin-right: 11px;
            }
        }
        &-list{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-item{
                width: 185px;
                a{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    span{
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 0.6rem;
                        line-height: 0.8rem;
                    }
                    .count{
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: 24px;
                        padding: 0px 13px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFFFFF;
                        font-size: 0.7rem;
                        line-height: 1rem;
                        font-weight: bold;
                        &-active{
                            background: rgba(232, 225, 63, 0.1);
                            color: #E8E13F;
                        }
                    }
                }
            }
        }
        .create{
            display: none;
        }
    }
    &-body{
        display: flex;
        align-items: flex-start;
        width: 100%;
        &-list{
            display: flex;
            flex-direction: column;
            width: 100%;
            &-item{
                width: 100%;
                height: 47px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #1B1F2A;
                border-radius: 12px;
                padding: 0px 11px;
                margin-bottom: 10px;
                .id{
                    background: rgba(232, 225, 63, 0.1);
                    padding: 0px 10px;
                    height: 26px;
                    border-radius: 24px;
                    color: #E8E13F;
                    font-size: 0.7rem;
                    line-height: 1rem;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                }
                .name{
                    width: 100%;
                    color: #FFFFFF;
                    font-size: 0.7rem;
                    line-height: 1rem;
                    font-weight: normal;
                }
                .date{
                    color: rgba(255, 255, 255, 0.4);
                    font-size: 0.7rem;
                    line-height: 1rem;
                    margin-right: 14px;
                }
                .status.active{
                    background: #E8E13F;
                    border-radius: 7.2px;
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        path{
                            stroke: #141720;
                        }
                    }
                }
                .status.resolved{
                    color: #46BE60;
                    font-size: 0.7rem;
                    line-height: 1rem;
                    font-weight: bold;
                }
            }
        } 
        &-nav{
            min-width: 216px;
            width: 216px;
            padding: 16px 17px 22px 17px;
            background: #1B1F2A;
            border-radius: 12px;
            margin-left: 30px;
            a{
                color: #E8E13F;
                font-size: 0.7rem;
                line-height: 1rem;
                margin-bottom: 14px;
                display: flex;
                align-items: center;
                font-weight: normal;
                svg{
                    margin-right: 11px;
                    &:last-child{
                        path{
                            stroke: #E8E13F;
                        }
                    }
                }
            }
            &-list{
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-bottom: 8px;
                &-item{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    a{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-between;
                        margin-bottom: 0px;
                        span{
                            color: rgba(255, 255, 255, 0.6);
                            font-size: 0.6rem;
                            line-height: 0.8rem;
                        }
                        .count{
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 24px;
                            padding: 0px 13px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #FFFFFF;
                            font-size: 0.7rem;
                            line-height: 1rem;
                            font-weight: bold;
                            &-active{
                                background: rgba(232, 225, 63, 0.1);
                                color: #E8E13F;
                            }
                        }
                    }
                }
            }
            .create-ticket{
                width: 100%;
                height: 41px;
                color: #141720;
                font-size: 0.7rem;
                line-height: 1rem;
                font-weight: bold;
            }
        }
    }
    &-body-empty{
        width: 100%;
        height: calc(100vh - 190px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span{
            color: rgba(255, 255, 255, 0.7);
            font-size: 0.85rem;
            line-height: 1.15rem;
            margin-bottom: 32px;
            margin-top: -100px;
        }
    }

    & .tickets-body-empty .yellow-button {
        padding: 11px 48px;
    }
}

@media screen and (max-width: 1024px) {
    .tickets{
        &-header{
            margin-bottom: 14px;
            h2{
                font-size: 0.9rem;
                line-height: 1.2rem;
            }
            .create{
                display: none;
            }
        }
        &-info{
            padding: 16px 14px 22px 14px;
            h3{
                margin-bottom: 14px;
            }
            &-list{
                flex-direction: column;
                padding-bottom: 8px;
                &-item{
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
            .create{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 41px;
                color: #141720;
                font-size: 0.7rem;
            }
        }
        &-body{
            flex-direction: column-reverse;
            &-list{
                &-item{
                    height: 52px;
                    padding: 0px 12px;
                }
            }
            &-nav{
                width: 100%;
                padding: 16px 14px 22px 14px;
                margin-left: 0px;
                margin-bottom: 12px;
            }
        }
    }
}