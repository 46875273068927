.mines {

  &__game {
    position: relative;
  }

  &__game-header {
    display: flex;
    align-items: center;

    & .mines__back {
      padding-top: 25px;
    }
  }

  &__total-cost {
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 700;
      font-size: 14px;
      color: #FFFFFF;

    }
  }

  &__about-game {
    position: relative;
    margin-top: 18px;
    padding: 35px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #1B1F2A;
    border-radius: 12px;
    grid-column-gap: 10px;
    z-index: 1;

    @media (max-width: 1281px) and (min-width:1025px),
    (max-width: 700px) {
      // padding-bottom: 50px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 45px;
    }

    @media (max-width:1150px) and (min-width:1024px),
    (max-width: 520px) {
      margin-bottom: 55px;
      padding-bottom: 50px;
    }

    @media (max-width: 1100px) and (min-width:1024px) {
      margin-top: 47px;
    }


    & .mines__player {
      margin-right: 0;
    }

    & .mines__info {
      flex-direction: column-reverse;
    }

    & .card__round {
      position: relative;
      left: 0;
      transform: translateY(0);
    }
  }

  &__round {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    & .card__map {
      display: none;
    }
  }

  &__about-game-wrapper {
    position: relative;

    @media (max-width: 520px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__info-wrapper {
    @media (max-width: 520px) {
      position: absolute;
      width: 100%;
      bottom: -104px;
    }
  }

  &__timer-bottom {
    display: none;

    @media (max-width: 520px) {
      margin-bottom: 30px;
      display: block;

      & .timer-component {
        width: 100%;
      }
    }
  }

  &__code-bottom {
    display: none;
    width: 100%;
    padding: 6px 0;
    background: rgba(75, 104, 255, 0.1);
    border: 1px solid #4B68FF;
    border-radius: 8px;
    color: #4B68FF;
    font-weight: 600;
    font-size: 12px;

    @media (max-width: 520px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__delete {
    // margin-left: 15px;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 95, 49, 0.1);
    border-radius: 8px;

    @media (max-width: 1281px) and (min-width:1025px),
    (max-width: 700px) {
      display: none;
    }
  }

  &__delete-bottom {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(240, 95, 49, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 8px 0;
    margin: 10px 0 40px 0;

    @media (max-width: 1281px) and (min-width:1025px),
    (max-width: 700px) {
      display: flex;
      margin-top: 0;
    }

    span {
      margin-left: 3px;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      color: #E8533F;
    }

  }

  &__game {
    & .waiting {
      margin-left: 25px;

      @media (max-width: 1293px) {
        margin: 0;
      }
    }

    & .card__round {
      position: relative;

      @media (max-width: 1282px) and (min-width: 1024px),
      (max-width: 700px) {
        margin: 0;
      }
    }

    & .mines__round {
      position: relative;
      @media (max-width: 1281px) and (min-width:1025px),
      (max-width: 700px) {
        position: absolute;
        left: 35%;
        width: 100%;
        top: 76px;
        left: 0px;
      }

      @media (max-width:1150px) and (min-width:1024px),
      (max-width: 520px) {
        top: 132px;
        border-radius: 12px;
      }

      @media (max-width: 520px) {
        background: #1B1F2A;
        border: 1px solid #1B1F2A;
      }

      & .card__round {

        margin: 0px 0;
        display: block;

        @media (max-width: 700px) {
          display: flex;
          align-items: center;
        }

        & p {
          margin-bottom: 10px;

          @media (max-width: 700px) {
            margin-bottom: 0px;
          }
        }
      }

    }

    @media (max-width: 1195px) and (min-width: 1025px) {
      & .mines__nickname {
        width: 120px;
      }
    }

    & .mines__timer {

      &.disabled {
        display: none;
      }

      @media (min-width: 1281px) {
        position: absolute;
        top: -105px;
      }

      @media (max-width: 1281px) and (min-width:1025px),
      (max-width: 700px) {
        width: 100%;

        & .timer-component {
          background-color: #24272F;
          width: 100%;
          align-items: center;
        }
      }

      @media (max-width: 520px) {
        display: none;
        position: absolute;
        bottom: 497px;
      }
    }


    & .mines__player {

      @media (max-width:1150px) and (min-width:1024px),
      (max-width: 520px) {
        .mines__turn {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 15px;
          width: 45%;
          left: 10px;
        }

        .mines__info {
          display: grid;
          flex-direction: column-reverse;
        }
      }
    }

    & .two.mines__player {
      .mines__turn {

        @media (max-width:1150px) and (min-width:1024px),
        (max-width: 520px) {
          margin-left: 50%;
        }

        @media (max-width: 1170px) and (min-width: 1025px) {
          & .timer-component {
            width: 100%;

          }
        }
      }

      &__delete {
        margin-left: 15px;
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(240, 95, 49, 0.1);
        border-radius: 8px;

        &-bottom {
          display: none;
          align-items: center;
          justify-content: center;
          background-color: rgba(240, 95, 49, 0.1);
          border-radius: 8px;
          width: 100%;
          padding: 8px 0;
          margin: 10px 0 40px 0;

          @media (max-width: 1281px) and (min-width:1025px),
          (max-width: 700px) {
            display: flex;
          }

          span {
            margin-left: 3px;
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            color: #E8533F;
          }
        }

        @media (max-width: 1281px) and (min-width:1025px),
        (max-width: 700px) {
          display: none;
        }


      }
    }
  }
}